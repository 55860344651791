import React, { Component } from "react"
import styles from "./Slide.module.scss"
import Img from "gatsby-image"

type Props = {
  slide?: Object<any>,
  isTitle: boolean,
}

class Slide extends Component<Props> {
  constructor(props) {
    super(props)
  }

  defineSlideType = (slide: Object<any>) => {
    const type = slide.__typename?.match(/Type\d+/)
    switch (type[0]) {
      case "Type1":
        return this.createTemplate1st(slide)
      case "Type2":
        return this.createTemplate2nd(slide)
      case "Type3":
        return this.createTemplate3rd(slide)
      default:
        return null
    }
  }

  createTemplate1st = ({ image, sign }: Object<{
    image: Object<any>,
    sign: string
  }>) => {
    sign = sign || ""
    return (
      <div className={`${styles.template} ${styles._1st}`}>
        {image.fluid && <Img loading={"eager"} fadeIn={true} fluid={image.fluid} className={styles.image}/>}
        <span className={styles.sign}>{sign}</span>
      </div>
    )
  }

  createTemplate2nd = ({ author, sign, quote }: Object<{
    author: string,
    sign: string,
    quote: Object<string>
  }>) => {

    author = author ?? ""
    sign = sign ?? ""
    quote = quote ?? { quote: "" }

    return (
      <div className={`${styles.template} ${styles._2nd}`}>
        <div>{this.props.info}</div>
        <span className={styles.author}>{author}</span>
        <span className={styles.sign}>{sign}</span>
        <blockquote className={styles.quote}>{quote.quote}</blockquote>
      </div>
    )
  }

  createTemplate3rd = ({ image, subtitle, text, additionalText }: Object<{
    image: Object<any>,
    subtitle: string,
    text: Object<string>,
    additionalText: Object<string>
  }>) => {

    return (
      <div className={`${styles.template} ${styles._3rd}`}>
        <h3 className={styles.subtitle}>{subtitle}</h3>
        <div className={styles.content}>
          {image?.fluid
            ? <Img {...image} loading={"eager"} fadeIn={true} className={`${styles.image}`}/>
            : <p>{additionalText.additionalText}</p>}
          {text && <p className={styles.text}>{text.text}</p>}
        </div>
      </div>
    )
  }

  render() {
    if (this.props.isTitle) {
      return (
        this.props.creationDate && this.props.projectName && this.props.projectShortDescription &&
        <div className={`${styles.slide} ${styles.mobileProjectData}`} id={this.props.id}>
          <span className={styles.creationDate}>{this.props.creationDate}</span>
          <span className={styles.projectName}>{this.props.projectName}</span>
          <span className={styles.shortDescription}>{this.props.projectShortDescription}</span>
        </div>
      )
    } else {
      return (
        <div className={`${styles.slide}`} id={this.props.id}>
          {
            this.props.creationDate && this.props.projectName && this.props.projectShortDescription &&
            <div className={styles.desktopProjectData}>
              <span className={styles.creationDate}>{this.props.creationDate}</span>
              <span className={styles.projectName}>{this.props.projectName}</span>
              <span className={styles.shortDescription}>{this.props.projectShortDescription}</span>
            </div>
          }
          {this.defineSlideType(this.props.slide)}
        </div>
      )
    }
  }
}

Slide.defaultProps = {
  id: 0,
  isTitle: false,
  title: "",
  description: "",
  sign: "",
  media: [],
}

export default Slide