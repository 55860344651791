/**
 * AG = Appearing AppearingGrid
 * AGI = Appearing AppearingGrid Item(s)
 */

import React, { Component } from "react"
import propTypes from "prop-types"
import styles from "./AboutUs.module.scss"
import Img from "gatsby-image"
import AppearingGrid from "../AppearingGrid/AppearingGrid"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { ASMouseTransforms } from "../ASMouse/transform-kinds"
import ASMouseWrapperComponent from "../ASMouseWrapper/ASMouseWrapperComponent"

type Props = {
  navId: string,
  title: string,
  content: {
    content: string
  },
  imageCollections: Array<any>,
}

class AboutUs extends Component<Props> {
  state = {
    AGChildren: null,
  }
  aboutUsRef = React.createRef()
  text = React.createRef()

  constructor(props) {
    super(props)

    this.animationTimeout = null
    this.changeActiveWordInterval = null
    this.activeWord = 0

    this.images = this.simplifyChildrenStructure(this.props.imageCollections)

    const content = this.props.content.json
    const options = {
      renderMark: {
        [MARKS.CODE]: text => (
          <ASMouseWrapperComponent
            style={{ width: "100%", transform: "translate3d(0, -30%, 0)" }}
            className={styles.triggerWrapper}
            onMouseEnter={(e) => {
              clearTimeout(this.animationTimeout)
              this.stopAutoAnimation()
              window.transformMouse("line", ASMouseTransforms["line"], e.currentTarget.previousSibling)
            }}
            onMouseLeave={() => {
              this.animationTimeout = setTimeout(() => this.startAutoAnimation(), 4000)
              window.returnDefault()
            }}>
            <span
              className={styles.triggerWord}>{text}
            </span>
          </ASMouseWrapperComponent>
        ),
      },
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => children,
      },
    }

    this.desktopContent = documentToReactComponents(content, options)
  }

  componentDidMount(): void {
    this.sectionHeight = this.aboutUsRef.current.clientHeight
    let counter = 0
    for (let child of this.text.current.children) {
      child.onmouseover = this.onMouseTrigger
      child.onmouseout = this.onMouseTrigger
      child.dataset.number = counter++
    }

    this.words = document.querySelectorAll(`#aboutUs > div.AboutUs-module--text--3daCv > div > span`)
    this.startAutoAnimation()
    this.setState({
      AGChildren: this.gatsbyImagesGenerator(),
    })
  }


  /* It will be executed when one of the events on the trigger is triggered (mouseover, mouseout) */
  onMouseTrigger = (event) => {
    const triggerNumber = event.currentTarget.dataset.number
    const AGI = document.querySelectorAll(`#aboutUs .appearingGridItem`)
    for (let item of AGI) {
      if (item.classList.contains(`collection_${triggerNumber}`) && !item.classList.contains(styles.activeAGI)) {
        item.classList.add(styles.activeAGI)
      } else {
        item.classList.remove(styles.activeAGI)
      }
    }
  }

  showCurrentTriggerImages = () => {
    const AGI = document.querySelectorAll(`#aboutUs .appearingGridItem`)
    this.deactivateImages(AGI)
    setTimeout(() => this.activateImages(AGI), 600)
  }

  activateImages = (AGI) => {
    for (let item of AGI) {
      if (item.classList.contains(`collection_${this.activeWord}`) && !item.classList.contains(styles.activeAGI)) {
        item.classList.add(styles.activeAGI)
      }
    }
  }

  deactivateImages = (AGI) => {
    for (let item of AGI) {
      if (!item.classList.contains(`collection_${this.activeWord}`) || item.classList.contains(styles.activeAGI)) {
        item.classList.remove(styles.activeAGI)
      }
    }
  }

  startAutoAnimation = () => {
    this.activeWord = 0
    for (const word of this.words) {
      word.classList.add(styles.active)
    }

    this.showCurrentTriggerImages()
    this.changeActiveWordInterval = setInterval(() => {
      this.activeWord = this.activeWord + 1 < 3 ? this.activeWord + 1 : 0
      this.showCurrentTriggerImages()
    }, 5000)
  }

  stopAutoAnimation = () => {
    for (const word of this.words) {
      word.classList.remove(styles.active)
    }

    clearInterval(this.changeActiveWordInterval)
  }

  /* Simplifies Array<imageCollections> structure to a single-level array */
  simplifyChildrenStructure = (data: Array<any>): Array<{
    id: string,
    title: string,
    fluid: Object<any>,
    options: Object<any>,
  }> => {
    const resultArray = []
    data.forEach((collection, index) => {
      collection.images.forEach((image) => {
        resultArray.push({
          // id: image.image.id,
          // title: image.image.title,
          fluid: image.image.fluid,
          options: image.options,
          collectionId: index, /* this property will be used to track images that
          should be displayed when “mouse over” event is detected on one of the trigger words */
        })
      })
    })
    return resultArray
  }

  defineStylePattern = (number): Array<string> => {
    let pattern = [], sizes = []
    switch (number) {
      case 0:
        sizes = [this.sectionHeight / 100 * 19, this.sectionHeight / 100 * 38, this.sectionHeight / 100 * 48]

        pattern = [
          { top: "2vh", left: "5vw", width: sizes[0] + "px", height: sizes[0] + "px" },
          { top: "9vh", left: "10vw", width: sizes[1] + "px", height: sizes[1] + "px", zIndex: 2 },
          { top: "78vh", left: "-2vw", width: sizes[2] + "px", height: sizes[2] + "px" },
          { top: "76vh", left: "79vw", width: sizes[0] + "px", height: sizes[0] + "px", zIndex: 2 },
          { top: "50vh", left: "86vw", width: sizes[1] + "px", height: sizes[1] + "px" },
        ]
        break
      case 1:
        sizes = [this.sectionHeight / 100 * 22, this.sectionHeight / 100 * 40,
          this.sectionHeight / 100 * 64, this.sectionHeight / 100 * 28, this.sectionHeight / 100 * 47]

        pattern = [
          { top: "12vh", left: "11vw", width: sizes[0] + "px", height: sizes[0] + "px" },
          { top: "25vh", left: "-7vw", width: sizes[1] + "px", height: sizes[1] + "px", zIndex: 2 },
          { top: "80vh", left: "45vw", width: sizes[2] + "px", height: sizes[2] + "px" },
          { top: "13vh", left: "73vw", width: sizes[3] + "px", height: sizes[3] + "px", zIndex: 2 },
          { top: "-20vh", left: "80vw", width: sizes[4] + "px", height: sizes[4] + "px" },
        ]
        break
      default:
        sizes = [this.sectionHeight / 100 * 20, this.sectionHeight / 100 * 46,
          this.sectionHeight / 100 * 41, this.sectionHeight / 100 * 17, this.sectionHeight / 100 * 49]

        pattern = [
          { top: "20vh", left: "19vw", width: sizes[0] + "px", height: sizes[0] + "px", zIndex: 2 },
          { top: "-11vh", left: "-2vw", width: sizes[1] + "px", height: sizes[1] + "px" },
          { top: "70vh", left: "24vw", width: sizes[2] + "px", height: sizes[2] + "px" },
          { top: "77vh", left: "45vw", width: sizes[3] + "px", height: sizes[3] + "px", zIndex: 2 },
          { top: "25vh", left: "88vw", width: sizes[4] + "px", height: sizes[4] + "px" },
        ]
        break
    }
    return pattern
  }

  /* Returns an array of gatsby-image components with built-in children */
  gatsbyImagesGenerator = () => {
    return this.images.map((child, index) => {
      const pattern = this.defineStylePattern(child.collectionId)
      return <Img fluid={child.fluid}
                  style={pattern[index % 5]}
                  key={index}
                  className={`appearingGridItem collection_${child.collectionId}`}
      />
    })
  }

  render() {
    return (
      <section id={this.props.navId} className={styles.aboutUs} ref={this.aboutUsRef}>
        <AppearingGrid children={this.state.AGChildren || this.gatsbyImagesGenerator()}/>
        <h2 className={`section-title ${styles.title}`}>{this.props.title}</h2>
        <div className={styles.text} ref={this.text}>{this.desktopContent}</div>
      </section>
    )
  }
}

AboutUs.propTypes = {
  title: propTypes.string,
  content: propTypes.shape({
    content: propTypes.string,
  }),
  imageCollections: propTypes.array,
}

AboutUs.defaultProps = {
  title: "О нас",
  content: {
    content: "",
  },
  imageCollections: [],
}


export default AboutUs