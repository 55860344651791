import React, { Component } from "react"
import ASMouse from "./ASMouse"
import Portal from "../../utils/Portal"

class ASMousePortal extends Component {
  render() {
    return <Portal><ASMouse/></Portal>
  }
}

export default ASMousePortal