import React, { Component } from "react"
import ReactDOM from "react-dom"
import styles from "./ClientsTickerLine.module.scss"
import Client from "../Client/Client"

type Props = {
  clients: Array<any>,
  direction: string,
  isActive: boolean,
}

class ClientsTickerLine extends Component<Props> {
  ticker = React.createRef()
  firstRef = React.createRef()
  secondRef = React.createRef()

  constructor(props) {
    super(props)
    //TODO автоматически дублировать элементы
    this.clients = [...this.props.clients.filter(client => Object.keys(client).length > 0)]
    this.clientsRefs = []
    for (let i = 0; i < this.clients.length; i++) {
      const ref = React.createRef()
      this.clientsRefs.push(ref)
    }
    this.clientsParams = []

    if (this.props.direction === "right") {
      this.clientsRefs.reverse()
    }
  }

  componentDidMount(): void {
    const clientWidth = this.ticker.current.children[0].clientWidth
    const minClientsCount = Math.ceil(this.ticker.current.clientWidth / clientWidth)

    const clients = [...this.clients]
    if (clients.length < minClientsCount) {
      for (let i = clients.length - 1; i < minClientsCount; i += clients.length) {
        this.clients = [...this.clients, ...clients]
      }
    }

    const ReactClients = []
    this.clients.forEach(client => {
      // console.log({ client })
      const element = React.createElement(Client, { cover: client.localFile?.url }, null)
      ReactClients.push(element)
    })

    ReactDOM.render(
      <>
        <div className={`${styles.tickerLine__block} ${this.props.isActive ? styles.active : ""}`}
             ref={this.firstRef}
             style={{ animationDuration: this.calculateTickerDuration(this.clients.length) }}>{ReactClients}</div>
        <div className={`${styles.tickerLine__block} ${this.props.isActive ? styles.active : ""}`}
             ref={this.secondRef}
             style={{ animationDuration: this.calculateTickerDuration(this.clients.length) }}>{ReactClients}</div>
      </>,
      this.ticker.current)
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
    if (prevProps.isActive !== this.props.isActive && this.props.isActive) {
      if (this.firstRef.current && this.firstRef.current.classList)
        this.firstRef.current.classList.add(styles.active)
      if (this.secondRef.current && this.secondRef.current.classList)
        this.secondRef.current.classList.add(styles.active)
    }
  }

  calculateTickerDuration = (clientsCount: number): string => {
    const duration = Math.min(Math.max(clientsCount * 2, 10), 40)
    return duration + "s"
  }

  render() {

    return (
      <div className={styles.tickerLine} ref={this.ticker} style={{
        transform: `translateX(${this.props.direction === "left" ? 0 : "-50%"})`,
      }}>
        {
          this.props.clients.map((client, index) => {
            return <Client reference={this.clientsRefs[index]} cover={client.localFile?.url} key={index}/>
          })
        }
      </div>
    )
  }
}

ClientsTickerLine.defaultProps = {
  clients: [],
}

export default ClientsTickerLine