import { Component } from "react"
import ReactDOM from "react-dom"

// Use a ternary operator to make sure that the document object is defined
const portalRoot = typeof document !== `undefined` ? document.getElementById("portal") : null

class Portal extends Component {
  constructor() {
    super()
    // Use a ternary operator to make sure that the document object is defined

    this.el = typeof document !== `undefined` ? document.createElement("div") : null
  }

  componentDidMount() {
    portalRoot.appendChild(this.el)
    this.main = document.querySelector("main")
    this.setProperties()
  }

  componentWillUnmount = () => {
    portalRoot.removeChild(this.el)
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
    this.setProperties()
    console.log({ props: this.props, state: this.state })
  }

  setProperties = () => {
    portalRoot.className = this.props.portalRootProps.classList.join(" ")
    portalRoot.style.display = this.props.portalRootProps.style.display
    portalRoot.style.opacity = this.props.portalRootProps.style.opacity

    if (this.props.portalRootProps.style.display && this.props.portalRootProps.style.display.toLowerCase() === "none") {
      this.main.style.overflow = ""
    } else {
      this.main.style.overflow = "hidden"
    }
  }

  render() {
    const { children } = this.props
    if (this.el) {
      return ReactDOM.createPortal(children, portalRoot)
    } else {
      return null
    }
  }
}

Portal.defaultProps = {
  portalRootProps: {
    style: {},
    classList: [],
  },
}

export default Portal