import React, { Component } from "react"
import styles from "./ContactUs.module.scss"
import IconCheckBox from "../../assets/Icons/IconCheckBox"
import TextareaAutosize from "@material-ui/core/TextareaAutosize"
import { ASMouseTransforms } from "../ASMouse/transform-kinds"
import ASMouseWrapperComponent from "../ASMouseWrapper/ASMouseWrapperComponent"
import { scroller } from "react-scroll"
import { anchors } from "../../utils/Enums"

// TODO подчеркивание ссылки при наведении

type Props = {
  navId: string,
  title: string,
  address: string,
  contacts: Array<any>,
  inputTitles: Array<String>,
  inputPlaceholders: Array<String>,
  socials: Array<String>,
  sendButtonText: string,
  instagram: string,
  facebook: string,
  twitter: string,
}

class ContactUs extends Component<Props> {
  state = {
    policyAdopted: false,
    nameIsValid: true,
    emailIsValid: true,
    messageIsValid: true,
  }

  form = React.createRef()
  thanksMessage = React.createRef()

  nameRef = React.createRef()
  emailRef = React.createRef()
  messageRef = React.createRef()

  formSent = false

  constructor(props) {
    super(props)
    this.addresses = [
      this.props.moscowAddress.moscowAddress,
      this.props.dubaiAddress.dubaiAddress,
      this.props.londonAddress.londonAddress,
    ]

    this.emails = [
      this.props.emailForGeneralQuestions,
      this.props.emailForEventQuestions,
      this.props.emailForPress,
    ]
  }

  agreePolicy = () => {
    this.setState({
      policyAdopted: !this.state.policyAdopted,
    })
  }

  formValidate = () => {
    if (!this.formSent) {
      let nameIsValid = true
      let emailIsValid = true
      let messageIsValid = true
      if (this.nameRef.current.value.length === 0) {
        nameIsValid = false
      }
      if (this.emailRef.current.value.length === 0) {
        emailIsValid = false
      }
      if (this.messageRef.current.value.length === 0) {
        messageIsValid = false
      }

      this.setState({
        nameIsValid: nameIsValid,
        emailIsValid: emailIsValid,
        messageIsValid: messageIsValid,
      }, this.sendForm)
    } else {
      const main = document.querySelector("main")

      const scrollOptions = {
        duration: 2000,
        delay: 0,
        smooth: "easeInOutQuint",
        containerId: "document",
      }

      if (main.clientWidth > main.clientHeight) {
        scrollOptions["containerId"] = "main"
      }

      scroller.scrollTo(anchors.projects, scrollOptions)
    }
  }

  sendForm = () => {
    const state = this.state
    if (state.policyAdopted && state.nameIsValid && state.emailIsValid && state.messageIsValid) {
      this.form.current.classList.remove(styles.error)
      this.form.current.classList.add(styles.message)
      this.formSent = true
    } else {
      this.form.current.classList.add(styles.error)
    }
  }

  render() {
    return (
      <section className={styles.contactUs} id={this.props.navId}>
        <div className={styles.wrapper}>
          <h2 className={`${styles.title} ${styles.mobile}`}>{this.props.title.title}</h2>
          <div className={styles.content}>
            <h2 className={styles.title}>{this.props.title.title}</h2>
            <form className={styles.form} ref={this.form}>
              <div className={styles.block}>
                <label>
                  <span>{this.props.inputTitles[0]}</span>
                  <input className={!this.state.nameIsValid ? styles.error : ""}
                         ref={this.nameRef} type={"text"} placeholder={this.props.inputPlaceholders[0]} minLength={2}
                         maxLength={100}/>
                </label>
                <label>
                  <span>{this.props.inputTitles[1]}</span>
                  <input className={!this.state.emailIsValid ? styles.error : ""}
                         ref={this.emailRef} type={"text"} placeholder={this.props.inputPlaceholders[1]} minLength={2}
                         maxLength={40}/>
                </label>
              </div>
              <div className={styles.block}>
                <label>
                  <span>{this.props.inputTitles[2]}</span>
                  <TextareaAutosize rowsMax={3}
                                    className={`${styles.textarea} ${!this.state.messageIsValid ? styles.error : ""}`}
                                    ref={this.messageRef} cols={1} minLength={20} maxLength={1000}
                                    placeholder={this.props.inputPlaceholders[2]}/>
                </label>
              </div>
              <div className={styles.lastStep}>
                <div className={styles.confidentialPolicy}>
                  <div className={`${styles.checkboxWrapper} ${this.state.policyAdopted ? styles.checked : ""}`}
                       onClick={this.agreePolicy}>
                    <IconCheckBox/>
                    <div className={`${styles.checkbox} ${!this.state.policyAdopted ? styles.error : ""}`}/>
                  </div>
                  <ASMouseWrapperComponent
                    style={{
                      width: "100%",
                      height: "200%",
                      transform: "translate3d(0, -20%, 0)",
                    }}
                    onMouseEnter={(e) => {
                      window.transformMouse("line", ASMouseTransforms["line"], e.currentTarget.previousSibling)
                    }}
                    onMouseLeave={() => {
                      window.returnDefault()
                    }}
                    onClick={(e) => e.currentTarget.previousSibling.children[0].click()}>
                  <span>
                    {this.props.confidentialPolicyText + " "}
                    <a href={this.props.confidentialPolicyLink} target={"_blank"}>
                      {this.props.confidentialPolicyLinkText}
                    </a>
                  </span>
                  </ASMouseWrapperComponent>
                </div>
                <button type={"button"} className={"AS-Button"}
                        onClick={this.formValidate}>
                  <span className={styles.beforeSend}>{this.props.sendButtonText}</span>
                  <span className={styles.afterSend}>{this.props.buttonTextAfterSend}</span>
                </button>
              </div>
              <span className={styles.errorMessage}>{this.props.errorMessage}</span>
            </form>
            <pre className={styles.thanksMessage} ref={this.thanksMessage}>
              {this.props.afterSendMessage.afterSendMessage}
            </pre>
          </div>
          <div className={styles.sideBar}>
            <div className={styles.addresses}>
              {
                this.props.cityMarks.map((mark, i) => (
                  <div className={styles.address} key={i}>
                    <span className={styles.city}>{mark}:</span> {this.addresses[i]}
                  </div>
                ))
              }
            </div>

            <div className={styles.emails}>
              {
                this.props.emailMarks.map((mark, i) => (
                  <div className={styles.email} key={i}>
                    <span className={styles.for}>{mark}:</span>
                    <span className={styles.address}>{this.emails[i]}</span>
                  </div>
                ))
              }
            </div>

            <div className={styles.social}>
              <ASMouseWrapperComponent
                style={{
                  width: "100%",
                  height: "200%",
                  transform: "translate3d(-0%, -20%, 0)",
                }}
                onMouseEnter={(e) => {
                  window.transformMouse("line", ASMouseTransforms["line"], e.currentTarget.previousSibling)
                }}
                onMouseLeave={() => {
                  window.returnDefault()
                }}
                onClick={(e) => e.currentTarget.previousSibling.click()}>
                <a target={"_blank"} rel="noopener noreferrer" href={this.props.instagram}>{this.props.socials[0]}</a>
              </ASMouseWrapperComponent>
              <span>{" / "}</span>
              <ASMouseWrapperComponent
                style={{
                  width: "100%",
                  height: "200%",
                  transform: "translate3d(-16%, -20%, 0)",
                }}
                onMouseEnter={(e) => {
                  window.transformMouse("line", ASMouseTransforms["line"], e.currentTarget.previousSibling)
                }}
                onMouseLeave={() => {
                  window.returnDefault()
                }}
                onClick={(e) => e.currentTarget.previousSibling.click()}>
                <a target={"_blank"} rel="noopener noreferrer" href={this.props.facebook}>{this.props.socials[1]}</a>
              </ASMouseWrapperComponent>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

ContactUs.defaultProps = {
  address: "",
  contacts: [],
}

export default ContactUs