import React, { Component } from "react"
import { defineCurrentDevice } from "../../utils/AS-Scripts"
import { devices } from "../../utils/Enums"
import AboutUs from "./AboutUs"
import AboutUsMobile from "./AboutUsMobile"

/* This component decides what will be displayed depending on the user's device. */
class AboutUsDecider extends Component {
  constructor(props) {
    super(props)
    this.desktopVersion = <AboutUs
      navId={this.props.navId} title={this.props.title}
      content={this.props.content}
      imageCollections={this.props.imageCollections}
    />
    this.mobileVersion = <AboutUsMobile
      navId={this.props.navId}
      title={this.props.title}
      mobileCollection={this.props.mobileCollection}
    />

    this.state = {
      render: this.mobileVersion,
    }
  }


  componentDidMount(): void {
    window.addEventListener("resize", this.updateDeviceType)
    this.updateDeviceType()
  }

  updateDeviceType = () => {
    const device = defineCurrentDevice()
    if (device.screenWidth <= 768) {
      if (this.state.render !== this.mobileVersion) {
        this.setState({ render: this.mobileVersion })
      }
    } else {
      if (this.state.render !== this.desktopVersion) {
        this.setState({ render: this.desktopVersion })
      }
    }
  }

  render() {
    return (
      <>
        {this.state.render}
      </>
    )
  }
}

export default AboutUsDecider