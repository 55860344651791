/**
 * AG = Appearing AppearingGrid
 * AGI = Appearing AppearingGrid Item(s)
 */

import React, { Component } from "react"
import styles from "./AboutUs.module.scss"
import Img from "gatsby-image"
import scrollWrapper from "../ScrollWrapper/ScrollWrapper"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"

type Props = {
  navId: string,
  title: string,
  mobileCollection: Array<any>,
  progress?: number,
}

class AboutUsMobile extends Component<Props> {
  state = {
    progress: 0,
  }
  text = React.createRef()
  aboutUsRef = React.createRef()
  aboutUsHeightPercent


  constructor(props) {
    super(props)
    this.startPosition = 0
    const options = {
      renderMark: {
        [MARKS.CODE]: text => <span className={styles.triggerWord}>{text}</span>,
      },
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => children,
      },
    }

    this.step = this.calculateStep(this.props.progress)
    this.content = []

    this.props.mobileCollection.forEach((item, index) => {
      const text = documentToReactComponents(item.text.json, options)
      const images = item.images.map((image) => {
        return {
          fluid: image.fluid,
          collectionId: index,
        }
      })
      this.content.push({
        text: text,
        images: images,
      })
    })
  }

  componentDidMount(): void {
    this.screenHeight = this.aboutUsRef.current.parentNode.clientHeight
    this.appearingGridChildren = this.gatsbyImagesGenerator()

    let maxTextHeight = 0
    const children = this.text.current.children
    for (const child of children) {
      const childHeight = child.clientHeight
      maxTextHeight = childHeight > maxTextHeight ? childHeight : maxTextHeight
    }

    const sectionHeight = Math.max(this.text.current.previousSibling.clientHeight + maxTextHeight + 20, 100)
    this.aboutUsRef.current.style.minHeight = sectionHeight + "px"

    this.aboutUsHeightPercent = sectionHeight / this.screenHeight * 100
    this.freePixels = this.screenHeight - sectionHeight * 1.6

    const correctProgress = this.freePixels / 100 * this.props.progress
    this.startPosition = this.screenHeight - correctProgress - sectionHeight
    this.aboutUsRef.current.style.transform = `translateY(${this.startPosition}px)`
    this.aboutUsRef.current.style.webkitTransform = `translateY(${this.startPosition}px)`

    let counter = 0
    for (let child of this.text.current.children) {
      if (child.children.length > 0) {
        child.children[0].onclick = this.onMouseTrigger
        child.children[0].dataset.number = counter++
      }
    }

    setTimeout(() => {
      const AGI = document.querySelectorAll(".appearingGridItem")
      for (let item of AGI) {
        if (item.classList.contains(`collection_0`) && !item.classList.contains(styles.activeAGI)) {
          item.classList.add(styles.activeAGI)
        } else {
          item.classList.remove(styles.activeAGI)
        }
      }
    }, 100)

  }

  calculateStep = (progress) => {
    if (progress > 75) {
      return 2
    } else if (progress > 30) {
      return 1
    } else {
      return 0
    }
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
    this.prevProgress = prevProps.progress
    console.log(this.props.progress)

    if (this.props.progress !== this.prevProgress) {
      const correctProgress = this.freePixels / 100 * this.props.progress
      if (this.startPosition - correctProgress !== prevState.progress) {
        const progress = this.startPosition - correctProgress
        this.aboutUsRef.current.style.transform = `translateY(${progress}px)`
        this.aboutUsRef.current.style.webkitTransform = `translateY(${progress}px)`
      }

      this.step = this.calculateStep(this.props.progress)
      const prevStep = this.calculateStep(prevProps.progress)

      if (this.step !== prevStep) {
        const AGI = document.querySelectorAll(".appearingGridItem")
        for (let item of AGI) {
          if (item.classList.contains(`collection_${this.step}`) && !item.classList.contains(styles.activeAGI)) {
            item.classList.add(styles.activeAGI)
          } else {
            item.classList.remove(styles.activeAGI)
          }
        }
      }
    }
  }

  defineStylePattern = (number): Array<string> => {
    let pattern = [], sizes = []
    switch (number) {
      case 0:
        sizes = [this.screenHeight / 100 * 25, this.screenHeight / 100 * 55]

        pattern = [
          { top: "43vh", left: "26vw", width: sizes[0] + "px", height: sizes[0] + "px", zIndex: 2 },
          { top: "6vh", left: "-45vw", width: sizes[1] + "px", height: sizes[1] + "px" },
        ]
        break
      case 1:
        sizes = [this.screenHeight / 100 * 44, this.screenHeight / 100 * 60]

        pattern = [
          { top: "-10vh", left: "-30vw", width: sizes[0] + "px", height: sizes[0] + "px" },
          { top: "77vh", left: "55vw", width: sizes[1] + "px", height: sizes[1] + "px", zIndex: 2 },
        ]
        break
      default:
        sizes = [this.screenHeight / 100 * 28, this.screenHeight / 100 * 108]

        pattern = [
          { top: "62vh", left: "25vw", width: sizes[0] + "px", height: sizes[0] + "px", zIndex: 2 },
          { top: "35vh", left: "50vw", width: sizes[1] + "px", height: sizes[1] + "px" },
        ]
        break
    }
    return pattern
  }

  /* Returns an array of gatsby-image components with built-in children */
  gatsbyImagesGenerator = () => {
    return this.content.map((child, index) => {
      return child.images.map((image, img_index) => {
        const pattern = this.defineStylePattern(index)
        return <Img fluid={image.fluid}
                    style={pattern[img_index % 2]}
                    key={`${index}_${img_index}`}
                    className={`appearingGridItem collection_${image.collectionId}`}
        />
      })
    })
  }

  render() {
    const step = this.step
    return (
      <>
        <div className={styles.imagesContainer}>
          {this.appearingGridChildren}
        </div>
        <section className={`${styles.aboutUs} ${styles.mobile}`}
                 ref={this.aboutUsRef}>
          <h2 className={`section-title ${styles.title}`}>{this.props.title}</h2>
          <div className={styles.textMobile} ref={this.text}>
            {
              this.content.map((text, index) => (
                <div className={`${styles.text} ${step === index ? styles.activeMobileText : ""}`}
                     key={index}>{text.text}</div>
              ))
            }
          </div>
        </section>
      </>
    )
  }
}

AboutUsMobile.defaultProps = {
  title: "О нас",
  mobileCollection: [],
  progress: 0,
}

export default scrollWrapper(AboutUsMobile, "calc(var(--vh, 1vh) * 250)", 3)