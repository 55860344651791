import React, { Component } from "react"
import styles from "./ASMouseWrapper.module.scss"

type Props = {
  onMouseEnter?: void,
  onMouseLeave?: void,
  onClick?: void,
  className?: Object<any>,
  styles?: Object<any>
}

class ASMouseWrapperComponent extends Component<Props> {
  render() {
    return (
      <div className={styles.wrapperComponent + " " + this.props.className}>
        {this.props.children}
        <div className={styles.substrate + ' substrate'}
             style={this.props.style}
             onMouseEnter={this.props.onMouseEnter}
             onMouseLeave={this.props.onMouseLeave}
             onClick={this.props.onClick}
        />
      </div>
    )
  }
}

export default ASMouseWrapperComponent