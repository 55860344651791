import React, { Component } from "react"

class IconMobileMenu extends Component {
  render() {
    return (
      <svg width="30" height="9" viewBox="0 0 30 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="30" height="2" fill="white"/>
        <rect y="7" width="30" height="2" fill="white"/>
      </svg>
    )
  }
}

export default IconMobileMenu