import React, { Component } from "react"
import styles from "./MainScreen.module.scss"
import IconArrowDown from "../../assets/Icons/IconArrowDown"
import ButtonWithLine from "../ButtonWithLine/ButtonWithLine"
import { anchors } from "../../utils/Enums"
import ShowReelPopUp from "./ShowReelPopUp"
import { ASMouseTransforms } from "../ASMouse/transform-kinds"
import ASMouseWrapperComponent from "../ASMouseWrapper/ASMouseWrapperComponent"
import { scroller } from "react-scroll"

class MainScreen extends Component {
  state = {
    showreelIsActive: false,
  }

  logo = React.createRef()

  constructor(props) {
    super(props)
  }

  componentDidMount(): void {
    const preloader = document.getElementById("preloader-container")
    const main = document.getElementById("main")
    setTimeout(() => {
      preloader.style.background = "rgba(0,0,0,0)"
      setTimeout(() => {
        preloader.style.pointerEvents = "none"
        document.body.classList.add("loaded")
      }, 800)
    }, 1000)
  }

  scrollDown = () => {
    scroller.scrollTo(anchors.aboutUs, {
      duration: 1500,
      delay: 0,
      smooth: "easeInOutQuint",
      containerId: "main",
    })
  }

  toggleShowreel = () => {
    this.setState({ showreelIsActive: !this.state.showreelIsActive })
  }

  render() {
    return (
      <section className={styles.mainScreen}>
        <div className={styles.logo} id={"logo"} ref={this.logo}/>
        <div className={styles.scrollDownBtn} onClick={this.scrollDown}>
          <IconArrowDown/>
        </div>
        <ASMouseWrapperComponent
          style={{ width: "100%", transform: "translate3d(0, -30%, 0)" }}
          className={styles.showreel}
          onMouseEnter={(e) => {
            window.transformMouse("line", ASMouseTransforms["line"], e.currentTarget.previousSibling)
          }}
          onMouseLeave={() => window.returnDefault()}
          onClick={e => e.currentTarget.previousSibling.click()}
        >
          <div onClick={this.toggleShowreel}>
            {this.props.showreelText}
          </div>
        </ASMouseWrapperComponent>
        <div className={styles.swipeButton}>
          <ButtonWithLine direction={"down"} text={this.props.buttonsText.swipeButtonText} theme={"light"}
                          size={"large"}/>
        </div>
        <ShowReelPopUp active={this.state.showreelIsActive}
                       closePopUp={this.toggleShowreel}
                       showReelYouTubeID={this.props.showReelYouTubeID} backButtonsText={this.props.buttonsText.backButtonText}/>
      </section>
    )
  }
}

MainScreen.defaultProps = {
  showreelText: "",
}

export default MainScreen