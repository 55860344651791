import React, { Component } from "react"
import styles from "./Founder.module.scss"
import Img from "gatsby-image"
import ButtonWithLine from "../ButtonWithLine/ButtonWithLine"
import Portal from "../../utils/Portal"

type Props = {
  name: string,
  description: {
    description: string,
  },
  sign: {
    sign: string,
  },
  images: Array<any>,
  isActive: boolean
}

class Founder extends Component<Props> {
  founder = React.createRef()

  state = {
    portalRootProps: {
      style: {},
      classList: [],
    },
  }

  componentDidMount(): void {
    this.setState({
      portalRootProps: {
        style: { ...this.state.portalRootProps.style, display: "none" },
        classList: [...this.state.portalRootProps.classList, styles.founderPortal],
      },
    })
    this.founder.current.style.display = "none"
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
    if (this.props.isActive !== prevProps.isActive) {
      const { isActive } = this.props

      if (isActive) {
        this.founder.current.style.display = ""
        this.setState({
          portalRootProps: {
            classList: [styles.founderPortal],
            style: { ...this.state.portalRootProps.style, display: "" },
          },
        })

        setTimeout(() => this.setState({
          portalRootProps: {
            ...this.state.portalRootProps,
            style: { ...this.state.portalRootProps.style, opacity: 1 },
          },
        }), 50)
      } else {
        this.setState({
          portalRootProps: {
            ...this.state.portalRootProps,
            style: { ...this.state.portalRootProps.style, opacity: 0 },
          },
        })

        setTimeout(() => {
          this.founder.current.style.display = "none"

          this.setState({
            portalRootProps: {
              classList: [],
              style: { ...this.state.portalRootProps.style, display: "none" },
            },
          })
        }, 810)
      }
    }
  }

  render() {
    return (
      <Portal portalRootProps={this.state.portalRootProps}>
        <div className={`${styles.founder} ${styles[`type_${this.props.index + 1}`]}`} ref={this.founder}
             onClick={(e) => e.stopPropagation()}>
          <div className={styles.content}>
            <span className={styles.name}>{this.props.name}</span>
            <span className={styles.sign}>{this.props.sign.sign}</span>
            <p className={styles.description}>{this.props.description.description}</p>
          </div>
          <ButtonWithLine direction={"left"} text={this.props.buttonsText.backButtonText} theme={"light"}
                          action={() => this.props.activate(-1)}
                          style={{
                            wrapper: { top: "60%", transform: "translateY(-50%)" },
                            line: { width: 100 },
                          }}/>
          <ButtonWithLine direction={"right"} text={this.props.buttonsText.backButtonText} theme={"light"}
                          action={() => this.props.activate(-1)}
                          style={{
                            wrapper: { top: 0, right: 0, left: "unset", width: "fit-content" },
                            line: { width: 100 },
                          }}/>
          <Img className={`${styles.frontImg} ${styles.Img}`} fluid={this.props.images[0].fluid}/>
          <Img className={`${styles.backImg} ${styles.Img}`} fluid={this.props.images[1].fluid}/>
        </div>
      </Portal>
    )
  }
}

Founder.defaultProps = {
  founder: {
    name: "",
    description: {
      description: "",
    },
    sign: {
      sign: "",
    },
    images: [],
  },
  buttonsText: {
    skipButtonText: "",
    backButtonText: "",
  },
}

export default Founder