import React, { Component } from "react"
import NavMenu from "./NavMenu"
import NavMenuMobile from "./NavMenuMobile"
import { defineCurrentDevice } from "../../utils/AS-Scripts"

type Props = {
  language: string,
  menuItems: Array<any>
}

class NavMenuDecider extends Component<Props> {
  constructor(props) {
    super(props)
    this.desktopVersion = <NavMenu {...props}/>
    this.mobileVersion = <NavMenuMobile {...props}/>

    this.state = {
      render: null,
    }
  }


  componentDidMount(): void {
    document.documentElement.setAttribute("id", "document")
    window.addEventListener("resize", this.updateWidth)
    this.updateWidth()
  }

  updateWidth = () => {
    const device = defineCurrentDevice()
    if (device.screenWidth > 960) {
      if (this.state.render !== this.desktopVersion) {
        this.setState({ render: this.desktopVersion })
      }
    } else {
      if (this.state.render !== this.mobileVersion) {
        this.setState({ render: this.mobileVersion })
      }
    }
  }

  render() {
    return (
      <>
        {this.state.render ? this.state.render :
          <NavMenu {...this.props}/>}
      </>
    )
  }
}

export default NavMenuDecider