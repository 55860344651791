import React, { Component } from "react"
import styles from "./Founders.module.scss"
import Img from "gatsby-image"
import Founder from "./Founder"
import ASMouseWrapperComponent from "../ASMouseWrapper/ASMouseWrapperComponent"
import { ASMouseTransforms } from "../ASMouse/transform-kinds"

type Props = {
  navId: string,
  founders: Array<any>
}

class Founders extends Component<Props> {
  initialState = {
    activeFounder: -1,
  }

  state = { ...this.initialState }

  constructor(props): void {
    super(props)
    this.founders = []
    this.founderRefs = []
    props.founders.forEach(data_founder => {
      const founder = {}
      founder.name = data_founder.name
      founder.description = data_founder.description
      founder.sign = data_founder.sign
      founder.images = data_founder.images
      this.founders.push(founder)

      const founderRef = React.createRef()
      this.founderRefs.push(founderRef)
    })
  }

  componentDidMount(): void {
    this.main = document.querySelector("main")
    this.foundersSection = document.querySelector(`#${this.props.navId}`)
    this.founderRefs.forEach((ref, index) => {
      ref.current.addEventListener("mouseleave", () => {
        const antherFounderIndex = index === 0 ? 1 : 0
        this.founderRefs[antherFounderIndex].current.style.opacity = ""
      })

      ref.current.addEventListener("mouseenter", () => {
        const antherFounderIndex = index === 0 ? 1 : 0
        this.founderRefs[antherFounderIndex].current.style.opacity = 0.4
      })
    })
  }

  shownFounder = (founderId) => {
    window.returnDefault()

    this.setState({
      activeFounder: founderId,
    })
  }

  render() {
    return (
      <section id={this.props.navId} className={styles.founders}>
        {
          this.founders.map((founder, index) => (
            <article className={styles.founder} key={index} onClick={(e) => {
              console.log("я еблан")
              this.shownFounder(index + 1)
            }}
                     ref={this.founderRefs[index]}>
              <span className={styles.name}>{founder.name}</span>
              <ASMouseWrapperComponent
                onMouseEnter={(e) => {
                  if (this.main.scrollTop > this.foundersSection.offsetTop - 10 && this.main.scrollTop < this.foundersSection.offsetTop + 10) {
                    window.transformMouse("founder", ASMouseTransforms["founder"], e.currentTarget.previousSibling)
                  }
                }}
                onMouseLeave={() => {
                  window.returnDefault()
                }}
                style={{ width: "115%", height: "115%", transform: "translate3d(-6%, -8%, 0px)", zIndex: 2 }}
                onClick={(e) => e.currentTarget.previousSibling.click()}>
                <Img className={styles.image} fluid={founder.images[0].fluid}/>
              </ASMouseWrapperComponent>
              <span className={styles.sign}>{founder.sign.sign}</span>
              <Founder activate={this.shownFounder} index={index + 1}
                       buttonsText={this.props.buttonsText}
                       isActive={index + 1 === this.state.activeFounder} {...founder}/>
            </article>

          ))
        }
      </section>
    )
  }
}

Founders.defaultProps = {
  founders: [],
}

export default Founders