import React, { Component } from "react"
import styles from "./ASMouseWrapper.module.scss"

function artSecretMouseWrapper(WrappedComponent) {
  return (
    class ASMouseWrapper extends Component {
      substrate = React.createRef()
      wrapped = React.createRef()

      onMouseEnter = (event) => {

      }

      onMouseLeave = () => {
        // window.returnDefault()
      }

      render() {
        return (
          <div className={styles.wrapper}>
            <WrappedComponent {...this.props}/>
            <div className={styles.substrate} ref={this.substrate} onMouseEnter={this.onMouseEnter}
                 onMouseLeave={this.onMouseLeave}/>
          </div>
        )
      }
    }
  )
}

export default artSecretMouseWrapper