import React, { PureComponent } from "react"
import styles from "./Projects.module.scss"
import moment from "moment"
import { devices, directions } from "../../utils/Enums"
import Project from "./Project/Project"
import TimelineScrollButton from "./TimelineScrollButton/TimelineScrollButton"
import { defineCurrentDevice } from "../../utils/AS-Scripts"
import { ASMouseTransforms } from "../ASMouse/transform-kinds"
import ASMouseWrapperComponent from "../ASMouseWrapper/ASMouseWrapperComponent"

type Props = {
  navId: string,
  projects: Object<any>
}

class ProjectsTimeline extends PureComponent<Props> {
  state = {
    activeProjectNumber: 0,
    projectsCovers: [],
    currentOffset: 0,
    galleryIsShown: false,
  }

  projects = React.createRef()
  projectsSection = React.createRef()
  timeline = React.createRef()
  TSBNext = React.createRef()
  TSBBack = React.createRef()
  activeProject = React.createRef()

  constructor(props) {
    super(props)
    this.userDevice = {
      type: "desktop",
    }

    this.title = this.props.projects.title
    this.btnText = this.props.projects.buttonText
    this.projects = [...this.props.projects.projects]
    this.projects.sort((a, b) => {
      if (moment(a.creationDate).isBefore(b.creationDate)) {
        return 1
      } else if (moment(a.creationDate).isAfter(b.creationDate)) {
        return -1
      } else {
        return 0
      }
    })

    this.children = null
    this.projectYear = 0
    this.state.projectsCovers = [] // Start and end coordinates (from-to) of projects on the "Timeline"
    this.projectsCoordinates = [] // Start and end coordinates (from-to) of projects on the "Timeline"
    this.startOffset = 0 // Offset for the Timeline's first element
    this.endOffset = 0 // Offset for the Timeline's last element
    this.maxOffset = 0 // Min offset for "Timeline"
    this.forceAnimationEnabled = false // If true, scrolling will not work
    this.scrollRightIsActive = false // Scroll right now activeInCollapsed
    this.scrollLeftIsActive = false // Scroll left now activeInCollapsed

    this.timelineContentRefs = []

    this.timelineContent = <div className={styles.content}>
      <div className={styles.nextButtonWrapper}>
        <ASMouseWrapperComponent
          className={styles.asWrapper}
          style={{
            width: 150,
            height: 150,
          }}
          onClick={(e) => e.currentTarget.previousSibling.click()}
          onMouseEnter={(e) => {
            this.enterOnRightScrollButton()
            window.transformMouse("magnet-arrow", ASMouseTransforms["magnet-arrow"], e.currentTarget.previousSibling)
          }}
          onMouseLeave={(e) => {
            window.returnDefault()
            this.leaveFromRightScrollButton()
          }}>
          <TimelineScrollButton
            direction={directions.right}
            reference={this.TSBNext}
            callbackClick={this.goNextProject}/>
        </ASMouseWrapperComponent>
      </div>

      <div className={styles.prevButtonWrapper}>
        <ASMouseWrapperComponent
          className={styles.asWrapper}
          style={{
            width: 150,
            height: 150,
          }}
          onClick={(e) => e.currentTarget.previousSibling.click()}
          onMouseEnter={(e) => {
            this.enterOnLeftScrollButton()
            window.transformMouse("magnet-arrow", ASMouseTransforms["magnet-arrow"], e.currentTarget.previousSibling)
          }}
          onMouseLeave={() => {
            window.returnDefault()
            this.leaveFromLeftScrollButton()
          }}>
          <TimelineScrollButton
            direction={directions.left}
            reference={this.TSBBack}
            callbackClick={this.goPrevProject}/>
        </ASMouseWrapperComponent>
      </div>
      {
        this.projects.map((project, index) => {
          const ref = React.createRef()
          this.timelineContentRefs.push(ref)
          return (
            <div key={index} className={styles.projectInfo}
                 ref={ref}>
              <h2 className={styles.name}>
                {project.name}
              </h2>
              <p className={styles.description}>
                {project.description.description}
              </p>
            </div>
          )
        })
      }
      <button className={"AS-Button " + styles.viewGalleryBtn} onClick={this.toggleGallery}>{this.btnText}</button>
    </div>
  }

  componentDidMount(): void {
    this.main = document.querySelector("main")
    this.updateElementsData()
    setTimeout(() => this.updateElementsData(), 200)
    this.userDevice = defineCurrentDevice()
    this.timelineSection = document.getElementById(this.props.navId)

    window.addEventListener("scroll", this.requestAnimationFrameCheck)
    window.addEventListener("mousewheel", this.requestAnimationFrameCheck)
    window.addEventListener("touchmove", this.requestAnimationFrameCheck)
    window.addEventListener("resize", this.updateElementsData)

    this.projectsSection.current.style.transitionDuration = "0ms"
    this.projectsSection.current.addEventListener("scroll", () => {
      this.getCurrentActiveProject(-this.projectsSection.current.scrollLeft)
    })

    this.timelineContentRefs[this.state.activeProjectNumber].current.style.opacity = 1
    setTimeout(() => this.updateElementsData(), 2000)
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
    if (prevState.activeProjectNumber !== this.state.activeProjectNumber) {

      this.maxSpeed = this.calculateSpeedForActiveProject()
      this.timelineContentRefs[this.state.activeProjectNumber].current.style.transitionDelay = ".3s"
      this.timelineContentRefs[this.state.activeProjectNumber].current.style.opacity = 1
      this.timelineContentRefs[prevState.activeProjectNumber].current.style.transitionDelay = ""
      this.timelineContentRefs[prevState.activeProjectNumber].current.style.opacity = ""
    }
  }

  requestAnimation
  animateStarted = false
  requestAnimationFrameCheck = () => {
    if ((window.scrollY > this.timelineSection.offsetTop && window.scrollY < this.timelineSection.offsetTop + this.timelineSection.scrollHeight) ||
      ((this.main.scrollTop > this.timelineSection.offsetTop && this.main.scrollTop < this.timelineSection.offsetTop + this.timelineSection.scrollHeight))) {
      if (!this.animateStarted) {
        this.requestAnimation = requestAnimationFrame(this.animate)
        this.animateStarted = true
      }
    } else {
      if (this.animateStarted) {
        window.cancelAnimationFrame(this.requestAnimation)
        this.animateStarted = false
      }
    }
  }

  toggleGallery = () => {
    this.setState({
      galleryIsShown: !this.state.galleryIsShown,
    })
  }

  /* Updates data such as Timeline's offsets, projects coordinates and covers data */
  updateElementsData = () => {
    if (this.projectsSection.current.children.length === 0) {
      setTimeout(() => this.updateElementsData(), 30)
      return
    }

    this.children = Array.from(this.projectsSection.current.children)
    this.children = this.children.filter(child => child.tagName === "ARTICLE")
    this.startOffset = this.timeline.current.clientWidth / 2
    this.endOffset = this.children[this.children.length - 1].clientWidth / 2
    this.maxOffset = -this.projectsSection.current.scrollWidth + this.startOffset + this.endOffset

    if (this.userDevice?.type !== devices.desktop) {
      this.children[this.children.length - 1].style.paddingRight = (document.body.clientWidth - this.children[this.children.length - 1].children[0].clientWidth) / 2 + "px"
    }


    if (this.state.activeProjectNumber === 0) {
      this.projectsSection.current.style.transform = `translateX(0px)`
    }
    const paddingLeft = this.startOffset - this.children[0].clientWidth / 2

    this.projectsSection.current.style.paddingLeft = `${paddingLeft}px`

    const projectsCoordinates = []
    const projectsCovers = []

    for (let child of this.children) {
      if (child.tagName === "ARTICLE") {
        projectsCoordinates.push({
          width: child.children[0].clientWidth,
          startCoordinate: child.offsetLeft,
          endCoordinate: child.offsetLeft + child.clientWidth,
          offsetLeft: child.offsetLeft,
        })

        const cover = child.children[0].children[2].children[1]
        if (cover) {
          const coverRect = cover.getBoundingClientRect()

          projectsCovers.push({
            width: cover.clientWidth,
            height: cover.clientHeight,
            offsetTop: coverRect.top + window.scrollY,
            offsetLeft: coverRect.left,
          })
        }
      }
    }

    this.setState({ projectsCovers: projectsCovers })
    this.projectsCoordinates = projectsCoordinates
    this.maxSpeed = this.calculateSpeedForActiveProject()
  }

  toggleActiveProject = (newProjectNumber: number) => {
    if (this.state.activeProjectNumber === newProjectNumber) {
      this.toggleGallery()
    } else {
      this.moveToProject(newProjectNumber)
      this.setState({
        activeProjectNumber: newProjectNumber,
      })
    }

  }

  goNextProject = () => {
    if (this.state.activeProjectNumber < this.projects.length - 1) {
      this.children[this.state.activeProjectNumber + 1].click()
    }
  }

  goPrevProject = () => {
    if (this.state.activeProjectNumber > 0) {
      this.children[this.state.activeProjectNumber - 1].click()
    }
  }

  goPrev = () => {
    this.setState({
      activeProjectNumber: this.state.activeProjectNumber - 1,
    })
  }

  goNext = () => {
    this.setState({
      activeProjectNumber: 3,
    })
  }

  enterOnRightScrollButton = () => {
    this.scrollRightIsActive = true
  }

  leaveFromRightScrollButton = async () => {
    this.scrollRightIsActive = false
    this.currentSpeed = 0
    this.moveToProject(this.state.activeProjectNumber)
  }

  enterOnLeftScrollButton = () => {
    this.scrollLeftIsActive = true
  }

  leaveFromLeftScrollButton = async () => {
    this.scrollLeftIsActive = false
    this.currentSpeed = 0
    this.moveToProject(this.state.activeProjectNumber)
  }


  //region Variables for the "Timeline" animation
  accelerationCoefficient = 0
  currentSpeed = 0
  frameDuration = 1500 // Time to display one project
  maxSpeed = 0 // Maximum possible scroll speed
  maxSpeedCoefficient = 1
  moveDirection = directions.right // Direction where the "Timeline" will move
  previousTimestamp = 0 // Timestamp of the last animated frame
  targetSpeed = 0
  //endregion

  //region Functions for animate the "Timeline"
  calculateSpeed = (ds) => Math.pow(ds, 0.25)

  getCurrentActiveProject = (offsetX: number) => {
    const currentOffset = -(offsetX - this.startOffset)
    const activeProjectNumber = this.projectsCoordinates.findIndex((project) => project.startCoordinate <= currentOffset && project.endCoordinate >= currentOffset)
    if (activeProjectNumber >= 0 && this.state.activeProjectNumber !== activeProjectNumber) {
      this.setState({
        activeProjectNumber: activeProjectNumber,
      })
    }
  }

  /**
   * Calculates speed for project, what now is activated
   * @returns {number}
   */
  calculateSpeedForActiveProject = () => {
    const projectWidth = this.projectsCoordinates[this.state.activeProjectNumber].width
    return projectWidth / this.frameDuration
  }

  moveToProject = (id: number) => {
    this.forceAnimationEnabled = true
    this.setState({
      activeProjectNumber: id,
    })

    if (this.userDevice?.type !== devices.mobile) {
      // Calculates new coordinates by X taking into account the shift of the image to the center
      const newCoordinate = -this.projectsCoordinates[id].startCoordinate + this.startOffset - this.projectsCoordinates[id].width / 2
      this.setTimelineTranslate(newCoordinate)
      this.projectsSection.current.style.transitionDuration = "600ms"

      setTimeout(() => {
        this.projectsSection.current.style.transitionDuration = "0ms"
        this.forceAnimationEnabled = false
      }, 300)
    } else {
      // Calculates new coordinates by X taking into account the shift of the image to the center
      // console.log("coordinates", this.projectsCoordinates, this.startOffset)
      let newCoordinate
      if (id !== 0) {
        newCoordinate = this.projectsCoordinates[id].startCoordinate - this.projectsCoordinates[id].width / 2
      } else {
        newCoordinate = 0
      }
      this.mobileScroll(newCoordinate)
    }
  }

  setTimelineTranslate = (newCoordinates: number) => {
    newCoordinates = Math.max(Math.min(newCoordinates, 0), this.maxOffset)
    this.projectsSection.current.style.transform = `translate3d(${newCoordinates}px, 0, 0)`
  }

  mobileScroll = (position) => {
    const animation = function() {
      let delta = (pos - target.scrollLeft) / 15

      if (delta < 0) {
        delta = Math.min(delta, -1)
      } else {
        delta = Math.max(delta, 1)
      }

      currentPosition += delta
      target.scrollLeft = currentPosition

      // console.log({ currentPosition, pos, delta })
      if (Math.floor(currentPosition) !== Math.floor(pos) && Math.ceil(currentPosition) !== Math.ceil(pos)) {
        window.requestAnimationFrame(animation)
      } else {
        // moving = false
      }
    }

    // console.log("m-scroll-pos", position)
    const target = this.projectsSection.current
    let pos = position
    let currentPosition = target.scrollLeft
    animation()
  }

  /* Animates "Timeline" moving */
  animate = (timestamp: number) => {
    const t = timestamp - this.previousTimestamp

    if (!this.forceAnimationEnabled) {

      // Set direction where the "Timeline" will move
      this.moveDirection = this.scrollRightIsActive
        ? directions.right : this.scrollLeftIsActive
          ? directions.left : this.moveDirection

      if (this.scrollRightIsActive || this.scrollLeftIsActive) {
        this.targetSpeed = this.maxSpeed * this.maxSpeedCoefficient
      } else {
        this.targetSpeed = 0
      }

      if (this.currentSpeed > 0 || this.scrollRightIsActive || this.scrollLeftIsActive) {
        const styles = getComputedStyle(this.projectsSection.current)
        const currentTranslateX = styles.transform.match(/matrix\(\d+, ?\d+, ?\d+, ?\d+, ?(-*\d+\.*\d*)/)[1]
        this.targetSpeed = (this.scrollRightIsActive && currentTranslateX < this.maxOffset + 50) || (this.scrollLeftIsActive && currentTranslateX > -50) ? 0 : this.targetSpeed

        // Time difference between last and current frame
        const dSpeed = this.currentSpeed - this.targetSpeed
        this.currentSpeed += -dSpeed * this.calculateSpeed(Math.abs(dSpeed)) / 10

        // dont move too close to end
        const dX = this.currentSpeed * t
        let newCoordinates = this.moveDirection === directions.right
          ? Math.round((currentTranslateX - dX) * 10) / 10
          : Math.round((parseFloat(currentTranslateX) + parseFloat(dX)) * 10) / 10


        this.setTimelineTranslate(newCoordinates)
        this.getCurrentActiveProject(newCoordinates)
      }
    }

    this.previousTimestamp = timestamp
    window.requestAnimationFrame(this.animate)
  }
  //endregion

  projectLoaded = () => {
    this.updateElementsData()
  }

  render() {
    return (
      <>
        <section className={styles.projects} id={this.props.navId}>
          <div className={styles.wrapper}>
            <div className={styles.timeline} ref={this.timeline}>
              <div className={styles.line}/>
              <div className={styles.projectSection + " timeline"} ref={this.projectsSection}
                   style={{ transform: "translate3d(0, 0, 0)" }}>
                {
                  this.projects.map((project, index) => {
                    const firstAnotherProject = index < this.projects.length - 1
                    const secondAnotherProject = index < this.projects.length - 2
                    const otherProjects = []
                    let anotherProject = {}
                    let fapNumber = 0, sapNumber = 0
                    if (firstAnotherProject) {
                      fapNumber = index + 1
                      anotherProject = {
                        name: this.projects[fapNumber].name,
                        slides: this.projects[fapNumber].slides,
                      }
                      otherProjects.push(anotherProject)
                    } else if (this.projects.length >= 1 && index !== 0) {
                      anotherProject = {
                        name: this.projects[fapNumber].name,
                        slides: this.projects[fapNumber].slides,
                      }
                      otherProjects.push(anotherProject)
                    }

                    if (secondAnotherProject) {
                      sapNumber = index + 2
                      anotherProject = {
                        name: this.projects[sapNumber].name,
                        slides: this.projects[sapNumber].slides,
                      }
                      otherProjects.push(anotherProject)
                    } else if (this.projects.length >= 2 && index !== 1) {
                      sapNumber = 1
                      anotherProject = {
                        name: this.projects[sapNumber].name,
                        slides: this.projects[sapNumber].slides,
                      }
                      otherProjects.push(anotherProject)
                    }

                    const projectData = {
                      id: project.id,
                      name: project.name,
                      description: project.description.description,
                      shortDescription: project.shortDescription.shortDescription,
                      creationDate: project.creationDate,
                      cover: project.cover,
                      slides: project.slides,
                    }

                    const creationDateYear = parseInt(moment(project.creationDate).format("YYYY"))
                    if (creationDateYear !== this.projectYear) {
                      this.projectYear = creationDateYear
                      return (
                        <React.Fragment key={index}>
                          <h2 className={styles.periodLabel}>{creationDateYear}</h2>
                          <Project
                            key={index}
                            id={index}
                            isActive={index === this.state.activeProjectNumber}
                            activateThisProject={() => this.toggleActiveProject(index)}
                            gallery={{
                              isShown: this.state.galleryIsShown && index === this.state.activeProjectNumber,
                              toggle: this.toggleGallery,
                            }}
                            otherProjects={otherProjects}
                            nextProjectText={this.props.projects.nextProjectText}
                            openProjectButton={this.props.projects.openProjectButton}
                            projectLoaded={this.projectLoaded}
                            goFap={() => this.toggleActiveProject(Math.min(this.projects.length - 1, fapNumber))}
                            goSap={() => this.toggleActiveProject(Math.min(this.projects.length - 1, sapNumber))}
                            buttonsText={this.props.buttonsText}
                            {...projectData}/>
                        </React.Fragment>
                      )
                    } else {
                      return (
                        <Project
                          key={index}
                          id={index}
                          reference={index === this.state.activeProjectNumber ? this.activeProject : null}
                          isActive={index === this.state.activeProjectNumber}
                          gallery={{
                            isShown: this.state.galleryIsShown && index === this.state.activeProjectNumber,
                            toggle: this.toggleGallery,
                          }}
                          otherProjects={otherProjects}
                          nextProjectText={this.props.projects.nextProjectText}
                          activateThisProject={() => this.toggleActiveProject(index)}
                          openProjectButton={this.props.projects.openProjectButton}
                          goFap={() => this.toggleActiveProject(Math.min(this.projects.length - fapNumber, fapNumber))}
                          goSap={() => this.toggleActiveProject(Math.min(this.projects.length - sapNumber, sapNumber))}
                          projectLoaded={this.projectLoaded}
                          buttonsText={this.props.buttonsText}
                          {...projectData}/>
                      )
                    }
                  })
                }
              </div>
            </div>
            {this.timelineContent}
          </div>
        </section>
      </>
    )
  }
}

ProjectsTimeline.defaultProps = {
  projects: {
    projects: [],
  },
}

export default ProjectsTimeline