import React, { Component } from "react"
import styles from "./Article.module.scss"
import propTypes from "prop-types"
import Img from "gatsby-image"
import Video from "../../Video/Video"
import { ASMouseTransforms } from "../../ASMouse/transform-kinds"
import ASMouseWrapperComponent from "../../ASMouseWrapper/ASMouseWrapperComponent"
import YouTube from "react-youtube"

type Props = {
  title: string,
  pages: Array<any>
}

class Article extends Component<Props> {
  definePageType = (page: Object<any>, index: number) => {
    const type = page.__typename?.match(/(\D+)(Type)(\D+)/)[3]
    switch (type) {
      case "One":
        return this.createTemplate1st(page, index)
      case "Two":
        return this.createTemplate2nd(page, index)
      case "Three":
        return this.createTemplate3rd(page, index)
      case "Four":
        return this.createTemplate4th(page, index)
      default:
        return null
    }
  }

  createTemplate1st = ({ description, sign, frontImage, backImage, index }) => {
    return (
      <article className={`${styles.template} ${styles._1st}`} key={index}>
        <div className={styles.column}>
          <p className={styles.description}>
            <span>“</span>
            {description.description}
            <span>“</span>
          </p>
          <span className={styles.sign}>{sign}</span>
        </div>
        <div className={styles.column}>
          <Img className={styles.frontImage} fluid={frontImage.fluid}/>
          <Img className={styles.backImage} fluid={backImage.fluid}/>
          <span className={`${styles.sign} ${styles.mobile}`}>{sign}</span>
        </div>
      </article>
    )
  }

  createTemplate2nd = ({ youTubeID, index }) => {
    return (
      <article className={`${styles.template} ${styles._2nd}`} key={index}>
        <YouTube
          videoId={youTubeID}
          containerClassName={styles.iframeContainer}
        />
      </article>
    )
  }

  createTemplate3rd = ({ description, sign, leftImage, rightImage, index }) => {
    return (
      <article className={`${styles.template} ${styles._3rd}`} key={index}>
        <div className={styles.column}>
          <p className={styles.description}>
            <span className={styles.quote}>“</span>
            {description.description}
            <span className={styles.quote}>“</span>
          </p>
          <span className={styles.sign}>{sign}</span>
          <Img className={styles.leftImage} fluid={leftImage.fluid}/>
        </div>
        <div className={styles.column}>
          <Img className={styles.rightImage} fluid={rightImage.fluid}/>
          <span className={`${styles.sign} ${styles.mobile}`}>{sign}</span>
        </div>
        <div className={`${styles.column} ${styles.mobile}`}>
          <div className={styles.subcolumn}>
            <span className={`${styles.sign} ${styles.mobile}`}>{sign}</span>
            <Img className={styles.leftImage} fluid={leftImage.fluid}/>
          </div>
          <div className={styles.subcolumn}>
            <Img className={styles.rightImage} fluid={rightImage.fluid}/>
          </div>
        </div>

      </article>
    )
  }

  createTemplate4th = ({ description, link, articleButtonText, image, index }) => {
    return (
      <article className={`${styles.template} ${styles._4th}`} key={index}>
        <div className={styles.column}>
          <p className={styles.description}>
            {description.description}
          </p>
          <div className={styles.linkWrapper}>
            <ASMouseWrapperComponent
              className={styles.linkAsWrapper}
              style={{
                width: "150%",
                height: "200%",
                transform: "translate3d(-16%, -20%, 0)",
              }}
              onMouseEnter={(e) => {
                window.transformMouse("line", ASMouseTransforms["line"], e.currentTarget.previousSibling)
              }}
              onMouseLeave={() => {
                window.returnDefault()
              }}
              onClick={(e) => e.currentTarget.previousSibling.click()}>
              <a href={link} target={"_blank"} rel="noopener noreferrer" className={styles.link}>{articleButtonText}</a>
            </ASMouseWrapperComponent>
          </div>
        </div>
        <div className={styles.column}>
          <div className={`${styles.linkWrapper} ${styles.mobile}`}>
            <a href={link} target={"_blank"} rel="noopener noreferrer" className={styles.link}>Перейти к статье</a>
          </div>
          <Img className={styles.image} fluid={image.fluid}/>
        </div>
      </article>
    )
  }


  render() {
    return (
      <div className={styles.article}>
        <section className={styles.pages}>
          <div className={styles.titleWrapper}>
            <span className={styles.title}>{this.props.title}</span>
          </div>
          {
            this.props.pages.map((page, index) => (
              this.definePageType(page, index)
            ))
          }
        </section>
      </div>
    )
  }
}

Article.propTypes = {
  title: propTypes.string,
  pages: propTypes.array,
}

Article.defaultProps = {
  title: "Заголовок",
  pages: [],
}

export default Article