import React, { Component } from "react"
import { defineCurrentDevice } from "../../utils/AS-Scripts"
import { anchors } from "../../utils/Enums"
import Founders from "./Founders"
import FoundersMobile from "./FoundersMobile"

/* This component decides what will be displayed depending on the user's device. */
class FoundersDecider extends Component {
  constructor(props) {
    super(props)
    this.desktopVersion = <Founders navId={anchors.founders} {...props}/>
    this.mobileVersion = <FoundersMobile navId={anchors.founders} {...props}/>

    this.state = {
      render: this.desktopVersion,
    }
  }


  componentDidMount(): void {
    window.addEventListener("resize", this.updateDeviceType)
    this.updateDeviceType()
  }

  updateDeviceType = () => {
    const device = defineCurrentDevice()
    if (device.screenWidth <= 768) {
      if (this.state.render !== this.mobileVersion) {
        this.setState({ render: this.mobileVersion })
      }
    } else {
      if (this.state.render !== this.desktopVersion) {
        this.setState({ render: this.desktopVersion })
      }
    }
  }

  render() {
    return (
      <>
        {this.state.render}
      </>
    )
  }
}

export default FoundersDecider