import React, { Component } from "react"
import styles from "../MainScreen/MainScreen.module.scss"
import lottie from "lottie-web"

class Preloader extends Component {
  logoRef = React.createRef()

  componentDidMount() {
    const logoLottie = require("../../assets/lottie/data.json")
    lottie.loadAnimation({
      container: document.getElementById("preloader"),
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: logoLottie,
    })
  }

  render() {
    return (
      <section style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "calc(var(--vh, 1vh) * 100)",
        width: "100vw",
        background: "#111111",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        opacity: 1,
        transition: ".8s",
        zIndex: 99,
      }} id={"preloader-container"}>
        <div className={styles.logo} id={"preloader"} ref={this.logoRef}/>
      </section>
    )
  }
}

export default Preloader