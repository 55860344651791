import React, { Component } from "react"

class IconArrowDown extends Component {
  render() {
    return (
      <svg width="31" height="47" viewBox="0 0 31 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8">
          <path d="M1 31.1914L15.3082 45.4996L29.6164 31.1914" stroke="white" strokeLinecap="square"/>
          <path d="M15.3081 45.5V0" stroke="white"/>
        </g>
      </svg>
    )
  }
}

export default IconArrowDown