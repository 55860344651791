import React, { Component, Ref } from "react"
import styles from "./NavMenu.module.scss"

type Props = {
  reference: Ref,
  style?: Object<any>
}

class Marker extends Component<Props> {
  render() {
    return (
      <div className={styles.marker} ref={this.props.reference} style={this.props.style}/>
    )
  }
}

Marker.defaultProps = {
  style: {},
}

export default Marker