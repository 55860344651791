import React, { Component } from "react"
import Img from "gatsby-image"
import styles from "./ImageCanvas.module.scss"

class  ImageCanvas extends Component {
  canvasRef = React.createRef()
  currentImage = 0
  prevTimestamp = 0
  prevY = 0
  prevX = 0
  border = {}
  setShift = false

  constructor(props) {
    super(props)
    this.images = [...props.images, ...props.images]
    this.imgRefs = this.images.map(() => React.createRef())
  }

  componentDidMount() {
    this.border = {
      top: this.canvasRef.current.offsetTop,
      left: this.canvasRef.current.offsetLeft,
      right: this.canvasRef.current.offsetLeft + this.canvasRef.current.clientWidth,
      bottom: this.canvasRef.current.offsetTop + this.canvasRef.current.clientHeight,
    }

    if (this.props.align === "left") {
      this.canvasRef.current.style.left = 0
    } else if (this.props.align === "right") {
      this.canvasRef.current.style.right = 0
    }

    this.canvasRef.current.addEventListener("mousemove", this.canvasMagic)

    this.canvasRef.current.parentNode.parentNode.addEventListener("mouseenter", () => {
      if (this.setShift) return

      if (!this.canvasRef.current) {
        return
      }
      const rect = this.canvasRef.current.getBoundingClientRect()
      if (this.props.align === "left") {
        this.canvasRef.current.style.left = (0 - rect.x) + "px"

      } else if (this.props.align === "right") {
        this.canvasRef.current.style.right = -(document.body.clientWidth - rect.x - rect.width) + "px"

      }

      this.canvasRef.current.style.bottom = 0
      this.setShift = true
    })
  }

  canvasMagic = (e) => {
    const timestamp = Date.now()
    const dt = timestamp - this.prevTimestamp

    const target = e.currentTarget
    const targetCoords = target.getBoundingClientRect()

    let x = e.clientX - targetCoords.left
    let y = e.clientY - targetCoords.top

    const dx = Math.abs(x - this.prevX)
    const dy = Math.abs(y - this.prevY)

    const dist = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2))

    if (dt < 330 || dist < 33) {
      return
    }

    this.prevTimestamp = timestamp

    const currentImage = this.currentImage
    if (currentImage + 1 === this.images.length) {
      this.currentImage = 0
    }


    const maxX = this.border.right - this.imgRefs[currentImage].current.clientWidth
    const maxY = this.border.bottom - this.imgRefs[currentImage].current.clientHeight

    if (x > maxX) {
      x = maxX
    }

    if (y > maxY) {
      y = maxY
    }

    this.prevX = x
    this.prevY = y

    const image = this.canvasRef.current.children[currentImage]
    image.style.left = x + "px"
    image.style.top = y + "px"
    image.style.opacity = 1

    this.currentImage += 1

    setTimeout(() => {
      image.style.opacity = 0
    }, 1300)
  }

  render() {
    return (
      <section className={styles.canvas} ref={this.canvasRef} style={{
        ...this.props.styles,
      }}>
        {
          this.images.map((image, index) => <Img key={index} className={styles.image} fluid={image.fluid}
                                                 ref={this.imgRefs[index]}/>)
        }
      </section>
    )
  }
}


ImageCanvas.defaultProps = {
  images: [],
  translateX: 0,
  translateY: 0,
  align: "left",
}


export default ImageCanvas