import React, { Component } from "react"
import styles from "./OurClients.module.scss"
import ClientsTickerLine from "./ClientsTickerLine/ClientsTickerLine"
import { defineCurrentDevice } from "../../utils/AS-Scripts"
import { devices } from "../../utils/Enums"
import { Element } from "react-scroll"

type Props = {
  navId: string,
  clients: Array<any>,
}

class OurClients extends Component<Props> {
  state = {
    isActive: true,
  }

  clientsRef = React.createRef()
  titleRef = React.createRef()

  componentDidMount(): void {
    // this.main = document.querySelector("main")
    // this.offsetTop = 0
    // setTimeout(() => {
    //   this.offsetTop = this.clientsRef.current.offsetTop - ((this.clientsRef.current.clientHeight / 70 * 100 - this.clientsRef.current.clientHeight) / 0.7)
    // }, 100)

    // window.addEventListener("mousewheel", this.checkFunc)
    // window.addEventListener("touchmove", this.checkFunc)
  }

  checkFunc = () => {
    // if (this.main.scrollTop >= this.offsetTop || window.scrollY >= this.offsetTop) {
    //   this.setState({ isActive: true })
    //   this.titleRef.current.style.opacity = 0
    //   window.removeEventListener("scroll", this.checkFunc)
    // }
  }

  render() {
    return (
      <section id={this.props.navId} className={styles.clients} ref={this.clientsRef}>
        {
          this.props.clients.lines.map((line, index) => (
            <ClientsTickerLine isActive={this.state.isActive} direction={index === 0 ? "left" : "right"}
                               clients={line.clients} key={index}/>
          ))
        }
      </section>
    )
  }
}

export default OurClients