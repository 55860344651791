import React, { Component } from "react"

class IconCheckBox extends Component {
  render() {
    return (
      <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.37487 8.47513L1.39987 5.50013L0.408203 6.4918L4.37487 10.4585L12.8749 1.95846L11.8832 0.966797L4.37487 8.47513Z"
          fill="white"/>
      </svg>
    )
  }
}

export default IconCheckBox
