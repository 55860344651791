export const directions = Object.freeze({
  up: "up",
  down: "down",
  left: "left",
  right: "right",
})

export const sizes = Object.freeze({
  small: "small",
  medium: "medium",
  large: "large",
})

export const anchors = Object.freeze({
  aboutUs: "aboutUs",
  projects: "projects",
  ourClients: "ourClients",
  mediaAboutUs: "mediaAboutUs",
  founders: "founders",
  contactUs: "contactUs",
})

export const devices = Object.freeze({
  desktop: "desktop",
  tablet: "tablet",
  mobile: "mobile",
})

export const deviceOrientations = Object.freeze({
  vertical: "vertical",
  horizontal: "horizontal",
})

export const vAligns = {
  top: "top",
  middle: "middle",
  bottom: "bottom",
}

export const hAligns = {
  left: "left",
  center: "center",
  right: "right",
}

