export const ASMouseTransforms = Object.freeze({
  "nav-item": {
    lock: false,
    radius: 0,
    style: {
      transform: "scale(1.5)",
    },
  },
  "line": {
    lock: true,
    radius: 0,
    style: {
      height: "0px",
      transform: "none",
    },
  },
  "magnet-arrow": {
    lock: true,
    radius: 0,
    style: {
      width: 60,
      height: 60,
      transform: "translate(-5%, -5%)",
    },
  },
  "founder": {
    lock: true,
    radius: 0,
    style: {
      transform: "none",
    },
  },
})