import React, { Component } from "react"

class IconTimelineArrow extends Component {
  render() {
    return (
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.9995 31L30.9039 16.0956L16.1919 1.38363" stroke="white" strokeLinecap="square"/>
        <path d="M30.9995 16H0.999512" stroke="white"/>
      </svg>
    )
  }
}

export default IconTimelineArrow