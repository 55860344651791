import React, { Component } from "react"
import Slide from "./Slides/Slide.js"
import styles from "./Slider.module.scss"
import SliderPagination from "./SliderPagination/SliderPagination"
import moment from "moment"
import ButtonWithLine from "../ButtonWithLine/ButtonWithLine"
import ImageCanvas from "../ImageCanvas/ImageCanvas"
import Portal from "../../utils/Portal"
import Img from "gatsby-image"
import IconLogo from "../../assets/Icons/IconLogo"
import { defineCurrentDevice } from "../../utils/AS-Scripts"
import { devices } from "../../utils/Enums"
import { animateScroll } from "react-scroll"
import Fade from "react-reveal/Fade"

type Props = {
  gallery: Object<any>,
  projectName: string,
  creationDate: string,
  slides: Array<any>,
}

// Using this to make sure that the document object is defined
const portalRoot = typeof document !== `undefined` ? document.getElementById("portal") : null

class Slider extends Component<Props> {
  initialState = {
    activeSlideNumber: 1,
    portalRootProps: {
      style: {
        display: "none",
        opacity: 0,
      },
      classList: [],
    },
  }

  state = { ...this.initialState }

  slider = React.createRef()
  slides = React.createRef()
  anotherProjectsRef = React.createRef()

  constructor(props) {
    super(props)
    this.creationDate = moment(this.props.creationDate).format("LL")
    this.prevProjectImages = this.props.otherProjects[0]?.slides?.filter(slide => slide.image).map(slide => slide.image) ?? []
    this.nextProjectImages = this.props.otherProjects[1]?.slides?.filter(slide => slide.image).map(slide => slide.image) ?? []
  }

  componentDidMount() {
    this.pagination = this.slider.current.querySelector(`div.pagination`)
    this.lastSlide = this.slides.current.children[this.slides.current.children.length - 1]
    this.device = defineCurrentDevice().type
    let opacityLine
    if (this.device === devices.desktop) {
      opacityLine = this.lastSlide.offsetTop + this.lastSlide.clientHeight
    } else {
      opacityLine = this.slider.current.clientHeight - this.anotherProjectsRef.current.clientHeight - this.lastSlide.clientHeight
    }

    portalRoot.addEventListener("scroll", () => this.checkScrollHeight(opacityLine))
    portalRoot.addEventListener("touchmove", () => this.checkScrollHeight(opacityLine))
    portalRoot.addEventListener("touchend", () => this.checkScrollHeight(opacityLine))
    this.turnSlidesRegulator()

    this.offsetSlideZone = this.slider.scrollHeight
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
    if (this.props.gallery.isShown !== prevProps.gallery.isShown) {
      this.turnSlidesRegulator()
      if (this.props.gallery.isShown) {
        this.setState({
          portalRootProps: {
            classList: [styles.wrapper],
            style: { ...this.state.portalRootProps.style, display: "" },
          },
        })
        setTimeout(() => {
          this.setState({
            portalRootProps: {
              ...this.state.portalRootProps,
              style: { ...this.state.portalRootProps.style, opacity: 1 },
            },
          })

          this.slidesOffsets = []
          for (const child of this.slides.current.children) {
            const offset = (child.offsetTop + child.clientHeight) - child.clientHeight / 100 * 25
            this.slidesOffsets.push(offset)
          }
        }, 50)
      } else {
        portalRoot.scrollTop = 0
        setTimeout(() => {
          this.setState({
            ...this.initialState,
            portalRootProps: {
              ...this.state.portalRootProps,
              style: { ...this.state.portalRootProps.style },
            },
          })
        }, 810)
      }
    }
  }

  checkScrollHeight = (offset): void => {
    if (portalRoot.scrollTop > offset) {
      // this.pagination.style.opacity = 0
    } else {
      // this.pagination.style.opacity = 1
    }
  }

  galleryToggle = () => {
    this.setState({
      portalRootProps: {
        ...this.state.portalRootProps,
        style: { ...this.state.portalRootProps.style, opacity: 0 },
      },
    })
    setTimeout(() => {
      this.setState({
        portalRootProps: {
          ...this.state.portalRootProps,
          style: { ...this.state.portalRootProps.style, display: "none" },
        },
      })
    }, 810)
    this.props.gallery.toggle()
  }

  turnSlidesRegulator = () => {
    if (!this.slider.current) {
      return
    }
    this.slider.current.scrollTop = 0

    if (this.props.gallery.isShown) {
      this.slider.current.style.display = ""
      portalRoot.addEventListener("wheel", this.defineCurrentSlide)
      portalRoot.addEventListener("touchmove", this.defineCurrentSlide)
      // window.addEventListener("touchmove", this.defineCurrentSlide)
    } else {
      this.slider.current.style.display = "none"
      portalRoot.removeEventListener("wheel", this.defineCurrentSlide)
      portalRoot.removeEventListener("touchmove", this.defineCurrentSlide)
      // window.removeEventListener("touchmove", this.defineCurrentSlide)
    }
  }

  setStateTimestamp = 0
  defineCurrentSlide = () => {
    let currentSlide = 0
    const scrollTop = portalRoot.scrollTop
    for (const [index, offset] of this.slidesOffsets.entries()) {
      if (scrollTop < offset) {
        currentSlide = index

        if (currentSlide !== this.state.activeSlideNumber) {
          this.setState({
            activeSlideNumber: Math.max(1, currentSlide),
          }, () => this.setStateTimestamp = Date.now())
        }
        break
      }
    }

    if (this.state.activeSlideNumber === 5 && Date.now() - this.setStateTimestamp > 500) {
      if (scrollTop > this.lastSlide.offsetTop) {
        this.pagination.style.opacity = 0
      } else {
        this.pagination.style.opacity = 1
      }
    }
  }

  choosesSlide = (event) => {
    const number = parseInt(event.currentTarget.dataset.number)
    if (number !== this.state.activeSlideNumber) {

      const scrollOptions = {
        duration: 1500,
        delay: 0,
        smooth: "easeInOutQuint",
        containerId: "portal",
      }

      animateScroll.scrollTo(this.slides.current.children[number].offsetTop, scrollOptions)
      this.setState({
        activeSlideNumber: number,
      })
    }
  }

  render() {
    return (
      <Portal portalRootProps={this.state.portalRootProps}>
        <section className={styles.slider} id={"slider_" + this.props.id} ref={this.slider} onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}>
          <ButtonWithLine direction={"left"} text={this.props.buttonsText.backButtonText} theme={"light"}
                          action={this.galleryToggle}
                          style={{
                            wrapper: { zIndex: 10, top: "50%", transform: "translateY(-50%)" },
                          }}/>
          <div className={styles.navmenu}>
            <IconLogo/>
            <ButtonWithLine direction={"right"} text={this.props.buttonsText.backButtonText} theme={"light"}
                            action={this.galleryToggle}
                            style={{ wrapper: { position: "static", zIndex: 10 } }}/>
          </div>
          <div className={styles.sliderZone} ref={this.slides}
               style={{ transform: "translateY(0px)" }}
               id={"sliderContainer"}>
            {
              <Slide
                id={"slide_0"}
                isTitle={true}
                creationDate={this.creationDate}
                projectName={this.props.projectName}
                projectShortDescription={this.props.projectShortDescription}>
              </Slide>
            }
            {
              this.props.slides.map((slide, index) => {
                const correctIndex = index + 1
                let props = {
                  key: { correctIndex },
                  id: `slide_${correctIndex}`,
                  slide: slide,
                  isTitle: false,
                  callback: () => {
                    this.setState({
                      activeSlideNumber: correctIndex,
                    })
                  },
                }

                if (index === 0) {
                  props = {
                    ...props,
                    creationDate: this.creationDate,
                    projectName: this.props.projectName,
                    projectShortDescription: this.props.projectShortDescription,
                  }
                }
                return <Fade bottom><Slide {...props}/></Fade>
              })
            }
          </div>
          <div className={styles.otherProjects} ref={this.anotherProjectsRef}>
            <span className={styles.nextProjectLabel}>{this.props.nextProjectText}</span>
            {
              this.props.otherProjects.length >= 1 &&
              <>
                <span onClick={this.props.goFap}
                      className={styles.prevProject}>{this.props.otherProjects[0].name}</span>
              </>
            }
            {
              this.props.otherProjects.length >= 2 ?
                <>
                <span onClick={this.props.goSap}
                      className={styles.nextProject}>{this.props.otherProjects[1].name}</span>
                </> : this.props.otherProjects.length === 1
                ? <span onClick={this.props.goFap}
                        className={styles.nextProject}>{this.props.otherProjects[0].name}</span>
                : null
            }
            {
              this.props.otherProjects.length >= 1 &&
              <>
                <button type={"button"}
                        onClick={this.props.otherProjects.length >= 2 ? this.props.goSap : this.props.goFap}
                        className={"AS-Button " + styles.openProjectButton}>{this.props.openProjectButton}</button>
              </>
            }
            {
              this.props.otherProjects.length >= 1 &&
              this.props.otherProjects[0].slides.length > 0 &&
              <>
                <Img fluid={this.props.otherProjects[0].slides[0].image.fluid} className={styles.nextProjectImage}/>
                <ImageCanvas
                  radius={350}
                  images={this.prevProjectImages}
                  styles={{
                    width: 500 + "px",
                    height: 500 + "px",
                  }}
                  align={"left"}
                />
              </>
            }
            {
              this.props.otherProjects.length >= 2 &&
              <ImageCanvas
                radius={350}
                images={this.nextProjectImages}
                styles={{
                  width: 500 + "px",
                  height: 500 + "px",
                }}
                align={"right"}
              />
            }
          </div>
          <SliderPagination slideCount={this.props.slides.length} activeSlide={this.state.activeSlideNumber}
                            callback={this.choosesSlide} active={this.props.gallery.isShown}/>
        </section>
      </Portal>
    )
  }
}


Slider.defaultProps = {
  gallery: {
    isShown: false,
    toggle: () => {
    },
  },
  buttonsText: {
    skipButtonText: "",
    backButtonText: "",
  },
}

export default Slider