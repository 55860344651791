import React, { Component } from "react"

class IconLogo extends Component {
  render() {
    return (
      <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path
            d="M18.864 19.4068C18.864 19.4068 18.864 19.3753 18.864 19.4068C18.9291 19.3753 18.9943 19.3753 19.0269 19.3438C19.1246 19.5958 19.2223 19.8478 19.2875 20.0054C20.2323 22.2737 21.4703 23.1244 23.2622 23.6914V23.849H16.9092V23.6914C19.2549 23.6914 19.6459 21.6436 18.864 19.4068Z"
            fill="white"/>
          <path
            d="M4.13775 19.1236C5.04998 16.9497 11.6962 0 11.6962 0H11.8591C11.8591 0 12.2501 0.945151 12.8365 2.39438C12.8039 2.45739 12.7714 2.5204 12.7714 2.58341V2.55191C12.3152 3.62308 10.0347 9.32549 8.14504 14.0828C8.11246 14.1773 8.04731 14.2403 8.04731 14.3348C6.90702 17.1702 5.92963 19.5961 5.60383 20.4783C4.62644 22.9672 5.3432 23.6918 7.03734 23.6918V23.8493C7.00476 23.8493 0.0327148 23.8493 0.0327148 23.8493V23.6918C2.21555 23.6918 3.16036 21.2974 4.13775 19.1236Z"
            fill="white"/>
          <path
            d="M28.475 22.5264C28.475 26.2755 25.2496 28.5124 20.5581 28.5124C18.6359 28.5124 16.7463 28.1028 15.6386 27.8508C14.5634 27.6302 12.8367 27.1262 12.8367 29.3945H12.6738V20.9197H12.8367C12.8367 24.0387 16.1598 28.3548 20.5581 28.3548C23.7183 28.3548 26.3899 26.244 26.3899 23.4716C26.3899 16.698 13.4557 17.3281 13.4557 9.89289C13.4557 5.89175 16.6811 4.25349 20.3952 4.25349C22.35 4.25349 23.9138 4.82058 25.445 5.23015C26.7157 5.5452 27.2369 5.19864 27.2369 3.33984H27.3998V11.8462H27.2369C27.2369 8.16011 24.6957 4.41102 20.4604 4.41102C18.1798 4.41102 15.2802 5.70272 15.2802 8.97925C15.2802 15.4063 28.475 15.3433 28.475 22.5264Z"
            fill="white"/>
        </g>
        <defs>
          <clipPath id="clip0">
            <path d="M0 0H28.6701V29.6147H0V0Z" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    )
  }
}

export default IconLogo