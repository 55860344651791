import React, { Component } from "react"
import styles from "./ScrollWrapper.module.scss"
import { animateScroll } from "react-scroll"

function scrollWrapper(WrappedComponent, height, scrollDiff = 0) {
  return (
    class ScrollWrapper extends Component {
      state = {
        scrollProgress: 0,
      }

      scrollWrapper = React.createRef()
      scrollContainer = React.createRef()

      screenHeight = 0
      wrapperHeight = 0
      scrollHeight = 0 // upper value of wrapper scroll height
      maxProgressPercent = 100 // upper percent scroll threshold
      minProgressPercent = 0 // lower percent scroll threshold
      tick = 1000 / 60
      prevTickTime = 0

      constructor(props) {
        super(props)
        this.style = { height: height }
      }

      componentDidMount(): void {
        const ua = navigator.userAgent.toLowerCase()
        if (ua.indexOf("safari") !== -1) {
          this.scrollDiff = ua.indexOf("chrome") > -1 ? 0 : scrollDiff
        }

        if (this.props.navId) {
          this.scrollWrapper.current.setAttribute("id", this.props.navId)
        }
        this.screenHeight = window.screen.height
        this.wrapperHeight = this.scrollWrapper.current.clientHeight
        this.scrollHeight = this.wrapperHeight - this.scrollContainer.current.clientHeight
        this.offsetTop = this.scrollWrapper.current.offsetTop
        this.endPosition = this.scrollWrapper.current.offsetTop + this.scrollWrapper.current.offsetHeight - this.scrollContainer.current.clientHeight
        this.wrapperClientHeight = this.scrollWrapper.current.clientHeight

        // window.requestAnimationFrame(this.onScroll)
        window.addEventListener("scroll", this.onScroll)
      }

      prevScroll = 0
      onScroll = () => {
        if (!this.scrollContainer.current || this.prevScroll === this.scrollContainer.current.offsetTop) {
          return
        }

        this.prevScroll = this.scrollContainer.current.offsetTop
        const offsetTop = this.scrollContainer.current.offsetTop - this.scrollWrapper.current.offsetTop

        let progress = 100 * (offsetTop / (this.scrollWrapper.current.scrollHeight - this.scrollContainer.current.clientHeight))
        progress = Math.max(0, Math.min(progress, 100))
        if (progress !== this.state.progress) {
          this.setState({
            scrollProgress: progress,
          })
        }
      }

      goto = (progress) => {
        const main = document.querySelector("main")
        const scrollToValue = this.scrollWrapper.current.offsetTop + (this.scrollWrapper.current.clientHeight - this.scrollContainer.current.clientHeight) / 100 * progress
        if (main.scrollTop === 0) {
          window.scrollTo(0, scrollToValue)
        } else {
          main.scrollTo(0, scrollToValue)
        }
      }

      smoothGoto = (progress) => {
        const main = document.querySelector("main")
        const scrollToValue = this.scrollWrapper.current.offsetTop + (this.scrollWrapper.current.clientHeight - this.scrollContainer.current.clientHeight) / 100 * progress

        const scrollOptions = {
          duration: 1500,
          delay: 0,
          smooth: "easeInOutQuint",
        }

        if (main.clientWidth > main.clientHeight) {
          scrollOptions["containerId"] = "main"
        }

        animateScroll.scrollTo(scrollToValue, scrollOptions)
      }

      render() {
        return (
          <section className={styles.scrollWrapper} style={this.style} ref={this.scrollWrapper}>
            <div className={styles.container} ref={this.scrollContainer}>
              <WrappedComponent {...this.props} scrollWrapper={{
                goto: this.goto,
                smoothGoto: this.smoothGoto,
              }} progress={this.state.scrollProgress}/>
            </div>
          </section>
        )
      }
    }
  )
}

export default scrollWrapper