import React, { Component } from "react"
import styles from "./NavMenu.module.scss"
import { anchors } from "../../utils/Enums"
import IconLogo from "../../assets/Icons/IconLogo"
import Marker from "./Marker"
import inView from "in-view"
import ButtonWithLine from "../ButtonWithLine/ButtonWithLine"
import IconMobileMenu from "../../assets/Icons/IconMobileMenu"

type Props = {
  menuItems: Array<string>,
  language: string,
}

class NavMenuMobile extends Component<Props> {
  navigationMenuWrapper = React.createRef()
  navigationMenuMarker = React.createRef()
  navigationMenu = React.createRef()
  backgroundRef = React.createRef()

  languageMenuMarker = React.createRef()
  languageMenu = React.createRef()

  mobileBurgerButton = React.createRef()
  currentSection = React.createRef()

  state = {
    isVisible: true,
    activeLanguage: 0,
    currentSection: 0,
    transformY: 0,
    transformX: 0,
    isOpen: false,
  }

  constructor(props) {
    super(props)
    this.firstClick = true
  }

  componentDidMount(): void {
    this.setState({ currentSection: 0 })
    this.navigationMenu.current.children[this.state.currentSection].classList.add(styles.current)
    inView.threshold(0.15)

    Object.keys(anchors).forEach((anchor, index) => {
      inView("#" + anchor).on("enter", () => {
        if (!this.state.isOpen) {
          this.setState({ currentSection: index })
        }
      })
    })

    if (this.props.language === "ru") {
      this.languageMenu.current.children[0].click()
    } else {
      this.languageMenu.current.children[1].click()
    }

  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    if (this.state.currentSection !== prevState.currentSection) {
      if (this.state.currentSection !== -1) {
        this.navigationMenu.current.children[this.state.currentSection].classList.add(styles.current)
      }
      if (prevState.currentSection !== -1) {
        this.navigationMenu.current.children[prevState.currentSection].classList.remove(styles.current)
      }
    }

    if (this.state.isOpen) {
      document.documentElement.style.overflow = "hidden"
    } else {
      document.documentElement.style.overflow = ""
    }
  }

  /* The function will work if the user clicks on a mobile navigation menu item */
  clickOnMenuItemMobile = (event) => {
    const target = event.currentTarget
    const additionalMarginTop = target.clientHeight / 2 // 1/2 height of clicked element. This is to center the marker.
    const targetOffsetTop = target.offsetTop
    const targetSectionNumber = +target.dataset.number

    if (targetSectionNumber !== this.state.currentSection) {
      this.mobileMarkerAnimate(targetOffsetTop, additionalMarginTop, targetSectionNumber)
    }
  }

  /**
   * Animates marker in mobile menu
   * @param offsetTop {Number} offset from the top of the menu
   * @param marginTop {Number} additional margin to align the marker in the center of the current menu item
   * @param sectionNumber {Number} current menu item number for update this state
   */
  mobileMarkerAnimate = (offsetTop: number, marginTop: number, sectionNumber: number = -1) => {
    // Start marker animation
    this.setState({ transformX: 53, currentSection: -1 })
    this.mobileBurgerButton.current.click()
    setTimeout(() => {
      this.setState({ transformY: offsetTop + marginTop })
      setTimeout(() => this.setState({ currentSection: sectionNumber }), 300)
      setTimeout(() => this.setState({ transformX: 0 }), 350)
      // setTimeout(() => this.mobileBurgerButton.current.click(), 1000)
    }, 300)
    // End marker animation
  }

  clickOnLanguageMenuItem = (event) => {
    const target = event.currentTarget
    const targetStyles = getComputedStyle(target) // CSS of clicked element
    const targetOffsetLeft = target.offsetLeft
    const targetWidth = parseFloat(targetStyles.width)

    this.languageMenuMarker.current.style.left = targetOffsetLeft + "px"
    this.languageMenuMarker.current.style.width = targetWidth + "px"
  }

  /* Functions will work if the user opens or closes the mobile menu */
  toggleVisibleMobileMenu = () => {
    this.navigationMenuWrapper.current.classList.toggle(styles.isActive)

    // The launch of the animated marker in the mobile menu is here,
    // because until that moment the menu items were hidden in the DOM
    if (this.state.currentSection !== -1) {
      const currentMenuItemOffsetTop = this.currentSection.current.offsetTop
      const additionalMarginTop = this.currentSection.current.clientHeight / 2

      this.navigationMenuMarker.current.style.transitionDuration = "0s"
      this.setState({ transformY: currentMenuItemOffsetTop + +additionalMarginTop, transformX: 0 })
      this.navigationMenuMarker.current.style.transitionDuration = "0.3s"
    }

    if (!this.state.isOpen) {
      this.navigationMenuMarker.current.style.transitionDuration = "0s"
      setTimeout(() => this.navigationMenuMarker.current.style.transitionDuration = "0.3s", 300)
    }

    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  goToEn = async (event) => {
    await this.clickOnLanguageMenuItem(event)
    if (this.firstClick) {
      this.firstClick = false
      return
    }
    window.location.href = "/en"
  }

  goToRu = async (event) => {
    await this.clickOnLanguageMenuItem(event)
    if (this.firstClick) {
      this.firstClick = false
      return
    }
    window.location.href = "/ru"
  }

  render() {
    return (
      <section id={"navigation-menu"} className={styles.navMenu}
               ref={this.navigationMenuWrapper}>
        <div className={styles.logo}>
          <IconLogo/>
          <div ref={this.mobileBurgerButton} className={styles.burger} onClick={this.toggleVisibleMobileMenu}>
            <IconMobileMenu/>
          </div>
        </div>
        <div className={styles.mobileBackground} ref={this.backgroundRef}>
          <div className={styles.logo}>
            <IconLogo/>
            <ButtonWithLine direction={"right"} text={this.props.buttonsText.backButtonText}
                            action={this.toggleVisibleMobileMenu}
                            style={{ wrapper: { position: "static", transform: "translate3d(30px, 0, 0)" } }}/>
          </div>
          <div className={styles.menuItems} ref={this.navigationMenu}>
            {
              this.props.menuItems.map((item, index) => (
                <a onClick={this.clickOnMenuItemMobile}
                   className={`${styles.menuItem}`}
                   key={index}
                   ref={this.state.currentSection === index ? this.currentSection : undefined}
                   data-number={index}
                   href={"#" + Object.keys(anchors)[index]}>
                  {item}
                </a>
              ))
            }
            <Marker style={{
              transform: `translate3d(-${this.state.transformX}px, ${this.state.transformY}px, 0)`,
            }} reference={this.navigationMenuMarker}/>
          </div>
          {
            <div className={styles.languages} ref={this.languageMenu}>
          <span className={`${styles.language} ${this.state.activeLanguage === 0 ? styles.current : ""}`}
                onClick={this.goToRu}>RU</span>
              <span className={`${styles.language} ${this.state.activeLanguage === 1 ? styles.current : ""}`}
                    onClick={this.goToEn}>EN</span>
              <Marker reference={this.languageMenuMarker}/>
            </div>
          }
        </div>
      </section>
    )
  }
}

NavMenuMobile.defaultProps = {
  menuItems: [],
  buttonsText: {
    swipeButtonText: "",
  },
}

export default NavMenuMobile