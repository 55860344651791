import React, { useEffect } from "react"
import PropTypes from "prop-types"

const Layout = ({ children }) => {
  useEffect(() => {
    const height = window.innerHeight
    const width = window.innerWidth

    document.documentElement.style.setProperty("--vh", `${height * 0.01}px`)
  }, [])

  return (
    <div
      style={{
        margin: `0 auto`,
        paddingTop: 0,
      }}
    >
      <main id={"main"}>{children}</main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
