import React, { Component } from "react"
import propTypes from "prop-types"
import styles from "./SliderPagination.js.module.scss"
import { defineCurrentDevice } from "../../../utils/AS-Scripts"
import { devices } from "../../../utils/Enums"

type Props = {
  slideCount: number,
}

//TODO смена номера активного слайда на мобилке
class SliderPagination extends Component<Props> {
  state = {
    isAnimate: false,
    isExtended: false,
  }

  separator = React.createRef()
  activeInCollapsed = React.createRef()
  activeInExtended = React.createRef()
  total = React.createRef()
  numbersList = React.createRef()
  upperNumbers = React.createRef()
  lowerNumbers = React.createRef()
  targetFrame = React.createRef()

  paginationExpanded = false

  constructor(props) {
    super(props)
  }

  componentDidMount(): void {
    this.calculate()
    this.device = defineCurrentDevice()
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
    if (this.props.active) {
      if (this.props.activeSlide !== prevProps.activeSlide) {
        this.targetFrame.current.style.top = this.activeInExtended.current?.offsetTop - 5 + "px"
        if (this.state.isExtended) {
          this.activeInExtended.current.classList.add(styles.current)
        }
      }

      if (this.props.active !== prevProps.active) {
        setTimeout(() => this.calculate(), 50)

      }
    }
  }

  calculate = () => {
    // maximum length of extended pagination part
    this.maximumChildrenLenght = this.upperNumbers.current.children.length > this.lowerNumbers.current.children.length
      ? this.upperNumbers.current.children.length
      : this.lowerNumbers.current.children.length

    // height of pagination parts
    this.upperNumbersHeight = this.upperNumbers.current.clientHeight
    this.lowerNumbersHeight = this.lowerNumbers.current.clientHeight



    // set default vertical shift to hide expanded pagination
    Array.from(this.upperNumbers.current.children).forEach(child => {
      child.style.transform = `translateY(${this.upperNumbersHeight}px)`
    })

    Array.from(this.lowerNumbers.current.children).forEach(child => {
      child.style.transform = `translateY(-${this.lowerNumbersHeight}px)`
    })
  }

  expand = () => {
    if (this.device.type === devices.mobile) return
    this.setState({
      isAnimate: true,
    })

    this.paginationExpanded = true
    let counter = 0
    let interval = setInterval(() => {
      if (counter === this.maximumChildrenLenght) {
        clearInterval(interval)
        this.setState({
          isExtended: true,
          isAnimate: false,
        })
        return
      }

      if (this.upperNumbers.current.children.length > counter) {
        this.upperNumbers.current.children[counter].style.transform = ""
      }

      if (this.lowerNumbers.current.children.length > counter) {
        this.lowerNumbers.current.children[this.lowerNumbers.current.children.length - counter - 1].style.transform = ""
      }

      counter++
    }, 100)
  }

  squeeze = () => {
    if (this.device.type === devices.mobile) return
    this.setState({
      isAnimate: true,
    })

    let counter = 0

    let interval = setInterval(() => {
      if (counter === this.maximumChildrenLenght) {
        clearInterval(interval)
        this.setState({
          isAnimate: false,
          isExtended: false,
        })
        return
      }

      if (this.upperNumbers.current.children.length > counter) {
        this.upperNumbers.current.children[counter].style.transform = `translateY(${this.upperNumbersHeight}px)`
      }

      if (this.lowerNumbers.current.children.length > counter) {
        this.lowerNumbers.current.children[this.lowerNumbers.current.children.length - counter - 1].style.transform = `translateY(-${this.lowerNumbersHeight}px)`
      }

      counter++
    }, 100)
  }

  mouseOver = () => {
    if (this.device.type === devices.mobile) return
    if (!this.state.isExtended) {
      this.setState({
        isExtended: true,
      })
    }
  }

  render() {
    return (
      <div className={styles.pagination + ' pagination' } onMouseEnter={this.expand}
           onMouseLeave={this.squeeze} onMouseOver={this.mouseOver}>
        <div className={styles.collapsed}>
          <span className={`${styles.number} ${styles.current} ${this.state.isExtended ? styles.active : ""}`}
                ref={this.activeInCollapsed}>{this.props.activeSlide}</span>
          <div className={`${styles.separator} ${this.state.isExtended ? styles.active : ""}`} ref={this.separator}/>
          <span className={`${styles.number} ${styles.total} ${this.state.isExtended ? styles.active : ""}`}
                ref={this.total}>{this.props.slideCount}</span>
        </div>

        <div className={`${styles.extended} ${this.state.isExtended ? styles.active : ""}`} ref={this.numbersList}>
          <div
            className={`${styles.targetFrame} ${this.state.isExtended && !this.state.isAnimate ? styles.active : ""}`}
            ref={this.targetFrame}/>
          <div className={styles.up} ref={this.upperNumbers}>
            {
              Array.from(Array(Math.floor(this.props.slideCount / 2)).keys()).map((slide, index) => (
                <span key={index}
                      data-number={slide + 1}
                      onClick={this.props.callback}
                      ref={slide + 1 === this.props.activeSlide ? this.activeInExtended : undefined}
                      className={`${styles.number}`}>{slide + 1}</span>
              ))
            }
          </div>
          <div className={styles.down} ref={this.lowerNumbers}>
            {
              Array.from(Array(Math.ceil(this.props.slideCount / 2)).keys()).map((slide, index) => (
                <div
                  key={index}
                  data-number={slide + Math.ceil(this.props.slideCount / 2)}
                  onClick={this.props.callback}
                  ref={slide + Math.ceil(this.props.slideCount / 2) === this.props.activeSlide ? this.activeInExtended : undefined}
                  className={`${styles.number}`}>{slide + Math.ceil(this.props.slideCount / 2)}</div>
              ))
            }
          </div>
        </div>
      </div>
    )
  }
}

SliderPagination.propTypes = {
  slideCount: propTypes.number,
}

export default SliderPagination