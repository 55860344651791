import React, { useEffect } from "react"
import Layout from "../components/layout"

import "../main.scss"
import MainScreen from "../components/MainScreen/MainScreen"
import { anchors } from "../utils/Enums"
import ProjectsTimeline from "../components/Projects/ProjectsTimeline"
import OurClients from "../components/OurClients/OurClients"
import MediaAboutUs from "../components/MediaAboutUs/MediaAboutUs"
import ContactUs from "../components/ContactUs/ContactUs"
import AboutUsDecider from "../components/AboutUs/AboutUsDecider"
import ASMouse from "../components/ASMouse/ASMouse"
import FoundersDecider from "../components/Founders/FoundersDecider"
import NavMenuDecider from "../components/NavMenu/NavMenuDecider"
import ASMousePortal from "../components/ASMouse/ASMousePortal"
import Preloader from "../components/Preloader/Preloader"

const IndexPage = ({ pageContext }) => {
  const node_locale = pageContext.node_locale || "another"

  useEffect(() => {
    document.querySelector("html").setAttribute("lang", node_locale)
  }, [])

  // console.log(pageContext)
  return (
    <Layout>
      <Preloader/>
      <NavMenuDecider language={node_locale} menuItems={pageContext.navMenu.items}
                      buttonsText={pageContext.buttonsText}/>
      <MainScreen buttonsText={pageContext.buttonsText}
                  showReelYouTubeID={pageContext.showReelYouTubeID}
                  showreelText={pageContext.showreelText}/>
      <AboutUsDecider navId={anchors.aboutUs} {...pageContext.aboutUs}/>
      <ProjectsTimeline navId={anchors.projects} projects={pageContext.ourProjects}
                        buttonsText={pageContext.buttonsText}/>
      <OurClients navId={anchors.ourClients} clients={pageContext.clients}/>
      <MediaAboutUs
        mediaAboutUsTitle={pageContext.mediaAboutUsTitle}
        navId={anchors.mediaAboutUs} articles={pageContext.articles}
        buttonsText={pageContext.buttonsText}/>
      <FoundersDecider founders={pageContext.founders} buttonsText={pageContext.buttonsText}/>
      <ContactUs navId={anchors.contactUs} {...pageContext.contactUs}/>
      <ASMousePortal/>
      <ASMouse/>
    </Layout>
  )
}

export default IndexPage