import React, { Component } from "react"
import styles from "./TimelineScrollButton.module.scss"
import IconTimelineArrow from "../../../assets/Icons/IconTimelineArrow"
import { directions } from "../../../utils/Enums"
import artSecretMouseWrapper from "../../ASMouseWrapper/ASMouseWrapper"

type Direction = $Keys<typeof directions>;

type Props = {
  direction: Direction,
  callbackClick: void,
  callbackOnEnter: void,
  callbackOnLeave: void,
}

class TimelineScrollButton extends Component<Props> {
  TSB = React.createRef()

  componentDidMount(): void {
    switch (this.props.direction) {
      case "left":
        this.TSB.current.classList.add(styles.left)
        break
      case "right":
        this.TSB.current.classList.add(styles.right)
        break
      default:
        this.TSB.current.classList.add(styles.right)
        break
    }
  }

  render() {
    if (!Object.keys(directions).includes(this.props.direction)) {
      return null
    }

    return (
      <button ref={this.TSB} data-direction={this.props.direction}
              className={styles.TSB}
              onClick={this.props.callbackClick}
              onMouseEnter={this.props.callbackOnEnter}
              onMouseLeave={this.props.callbackOnLeave}>
        <IconTimelineArrow/>
      </button>
    )
  }
}

TimelineScrollButton.defaultProps = {
  direction: directions.right,
  callbackOnEnter: () => {
  },
  callbackOnLeave: () => {
  },
}

export default  TimelineScrollButton