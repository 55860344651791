import React, { Component } from "react"
import styles from "./Founders.module.scss"
import Img from "gatsby-image"
import Founder from "./Founder"
import scrollWrapper from "../ScrollWrapper/ScrollWrapper"

type Props = {
  navId: string,
  founders: Array<any>,
  progress: number,
}

class FoundersMobile extends Component<Props> {
  initialState = {
    activeFounder: -1,
    step: 1,
    counterIsActive: false,
  }

  state = { ...this.initialState }
  foundersRef = React.createRef()

  constructor(props): void {
    super(props)
    this.founders = []
    this.founderRefs = []
    props.founders.forEach(data_founder => {
      const founder = {}
      founder.name = data_founder.name
      founder.description = data_founder.description
      founder.sign = data_founder.sign
      founder.images = data_founder.images
      this.founders.push(founder)

      const founderRef = React.createRef()
      this.founderRefs.push(founderRef)
    })
  }

  overflow = false
  prevProgress = 0

  componentDidMount(): void {
    this.wrapper = this.foundersRef.current.parentNode.parentNode
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
    if (this.props.progress !== prevProps.progress) {
      const progress = this.props.progress
      if (progress > 50) {
        this.setState({ step: 2 })
      } else {
        this.setState({ step: 1 })
      }
    }
  }

  shownFounder = (founderId) => {
    if ((founderId === 0 && this.state.step === 2) || (founderId === 1 && this.state.step === 1)) {
      return
    }
    const now = Date.now()
    const dt = now - this.prevTimestamp

    if (dt < 800) {
      return
    }

    this.prevTimestamp = now

    this.setState({
      activeFounder: founderId,
    }, () => {
      if (founderId === -1) {
        document.body.style.overflow = ""
        window.scrollTo(0, this.wrapper.offsetTop)
      } else {
        setTimeout(() => document.body.style.overflow = "hidden", 810)
      }
    })
  }

  render() {
    return (
      <section id={this.props.navId} className={styles.founders} ref={this.foundersRef}>
        {
          this.founders.map((founder, index) => (
            <article className={`${styles.founder} ${styles["step_" + this.state.step]}`} key={index}
                     onClick={() => this.shownFounder(index)}
                     ref={this.founderRefs[index]}>
              <span className={styles.name}>{founder.name}</span>
              <Img className={styles.image}
                   fluid={founder.images[0].fluid}/>
              <span className={styles.sign}>{founder.sign.sign}</span>
              <Founder activate={this.shownFounder} index={index}
                       buttonsText={this.props.buttonsText}
                       isActive={index === this.state.activeFounder} {...founder}/>
            </article>
          ))
        }
      </section>
    )
  }
}

FoundersMobile.defaultProps = {
  founders: [],
}

export default scrollWrapper(FoundersMobile, "calc(var(--vh, 1vh) * 250)")