import React, { Component } from "react"
import Article from "./Article/Article"
import styles from "./MediaAboutUs.module.scss"
import ButtonWithLine from "../ButtonWithLine/ButtonWithLine"
import scrollWrapper from "../ScrollWrapper/ScrollWrapper"
import { devices } from "../../utils/Enums"
import { defineCurrentDevice } from "../../utils/AS-Scripts"

type Props = {
  navId: string,
  articles: Array<any>
}

class MediaAboutUs extends Component<Props> {
  state = {
    translate: 0,
  }

  media = React.createRef()
  wrapper = React.createRef()
  skipBtn = React.createRef()
  skipBtnMobile = React.createRef()
  skipButtonActive = false
  prevProgress = 0

  scrollWidth
  allowSkip = false
  mediaZoneIsActive = false

  componentDidMount(): void {
    this.scrollWrapper = this.media.current.parentNode.parentNode
    this.scrollWrapper.setAttribute("id", this.props.navId)
    this.offsetTop = this.scrollWrapper.offsetTop
    this.scrollWidth = (this.wrapper.current.scrollWidth - this.media.current.clientWidth) / 100

    setTimeout(() => this.scrollWidth = (this.wrapper.current.scrollWidth - this.media.current.clientWidth) / 100, 1000)

    if (defineCurrentDevice().type !== devices.desktop) {
      window.addEventListener("scroll", () => {
        const currentScroll = window.scrollY
        const navMenu = document.getElementById("navigation-menu")
        if (currentScroll > this.offsetTop &&
          currentScroll < this.scrollWrapper.offsetTop + this.scrollWrapper.clientHeight - this.scrollWrapper.children[0].clientHeight &&
          this.mediaZoneIsActive === false) {
          this.mediaZoneIsActive = true
          navMenu.classList.add(styles.navMenuHide)
          setTimeout(() => navMenu.classList.add(styles.lessIndex), 300)
          setTimeout(() => this.media.current.classList.add(styles.activeZone), 350)
        } else if ((currentScroll < this.offsetTop ||
          currentScroll > this.scrollWrapper.offsetTop + this.scrollWrapper.clientHeight - this.scrollWrapper.children[0].clientHeight) &&
          this.mediaZoneIsActive === true) {
          this.mediaZoneIsActive = false
          navMenu.classList.remove(styles.lessIndex)
          setTimeout(() => navMenu.classList.remove(styles.navMenuHide), 100)
          setTimeout(() => this.media.current.classList.remove(styles.activeZone), 50)
        }
      })
    }
    const main = document.querySelector("main")
    window.addEventListener("scroll", () => {
      if (main.scrollTop < this.scrollWrapper.offsetTop) {
        this.scrollWrapper.style.scrollSnapAlign = "start"
      } else if (main.scrollTop > this.scrollWrapper.offsetTop + this.scrollWrapper.clientHeight - this.scrollWrapper.children[0].clientHeight) {
        this.scrollWrapper.style.scrollSnapAlign = "end"
      }
    })

    this.skipBtn.current.style.width = this.media.current.scrollWidth + "px"

    setTimeout(() => this.scrollWidth = (this.wrapper.current.scrollWidth - this.media.current.clientWidth) / 100, 2000)
  }

  prevCalculateProgress = 0

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
    if (this.props.progress !== prevProps.progress) {
      this.prevProgress = prevProps.progress
      if (this.props.progress > 25 && !this.allowSkip) {
        this.allowSkip = true
        this.skipBtn.current.classList.add(styles.show)
      } else if (this.props.progress < 25 && this.allowSkip) {
        this.allowSkip = false
        this.skipBtn.current.classList.remove(styles.show)
      }

      if (this.props.progress - 5 >= this.prevCalculateProgress || this.props.progress + 5 <= this.prevCalculateProgress) {
        this.prevCalculateProgress = this.props.progress
        this.scrollWidth = (this.wrapper.current.scrollWidth - this.media.current.clientWidth) / 100
      }

      window.requestAnimationFrame(() => {
        this.media.current.scrollLeft = this.scrollWidth * this.props.progress
      })
    }
  }

  skip = () => {
    const prevProgress = this.prevProgress
    const currentProgress = this.props.progress

    if (currentProgress === 100 || prevProgress > currentProgress) {
      this.props.scrollWrapper.smoothGoto(0)
    } else {
      this.props.scrollWrapper.smoothGoto(100)
    }
    this.skipButtonActive = false
  }

  render() {
    return (
      <section ref={this.media} className={styles.media}>
        <div className={styles.wrapper} ref={this.wrapper}>
          <div className={styles.titleWrapper}>
            <h2 className={styles.title}>{this.props.mediaAboutUsTitle}</h2>
          </div>
          {
            this.props.articles.map((article, i) => (
              <Article {...article} key={i}/>
            ))
          }
        </div>
        <div className={styles.skipBtnWrapper} ref={this.skipBtn}>
          <ButtonWithLine text={this.props.buttonsText.skipButtonText} theme={"light"} direction={"left"}
                          action={this.skip}/>
        </div>
        <div className={`${styles.skipBtnWrapper} ${styles.mobile}`} ref={this.skipBtnMobile} onClick={this.skip}>
          <ButtonWithLine text={this.props.buttonsText.skipButtonText} theme={"light"} direction={"right"}
                          action={this.skip}/>
        </div>
      </section>
    )
  }
}

MediaAboutUs.defaultProps = {
  articles: [],
  progress: 0,
}

export default scrollWrapper(MediaAboutUs, "calc(var(--vh, 1vh) * 500)")