import React, { Component } from "react"
import styles from "./NavMenu.module.scss"
import { anchors, devices } from "../../utils/Enums"
import IconLogo from "../../assets/Icons/IconLogo"
import Marker from "./Marker"
import inView from "in-view"
import ButtonWithLine from "../ButtonWithLine/ButtonWithLine"
import { defineCurrentDevice } from "../../utils/AS-Scripts"
import IconMobileMenu from "../../assets/Icons/IconMobileMenu"
import { ASMouseTransforms } from "../ASMouse/transform-kinds"
import ASMouseWrapperComponent from "../ASMouseWrapper/ASMouseWrapperComponent"
import { Events, animateScroll } from "react-scroll"

type Props = {
  menuItems: Array<string>,
  language: string,
}

class NavMenu extends Component<Props> {
  navigationMenuWrapper = React.createRef()
  navigationMenuMarker = React.createRef()
  navigationMenu = React.createRef()

  languageMenuMarker = React.createRef()
  languageMenu = React.createRef()

  mobileBurgerButton = React.createRef()
  currentSection = React.createRef()

  state = {
    isMobile: false,
    isVisible: false,
    activeLanguage: 0,
    currentSection: -1,
  }

  constructor(props) {
    super(props)
    this.firstClick = true
    this.device = {
      type: devices.desktop,
    }
  }

  componentDidMount(): void {
    const main = document.querySelector("main")

    Events.scrollEvent.register("begin", function(to, element) {
      main.style.scrollSnapType = "unset"
    })

    Events.scrollEvent.register("end", function(to, element) {
      main.style.scrollSnapType = ""
    })

    this.device = defineCurrentDevice()
    let isMobile = this.device.type === "mobile"
    if (isMobile) {
      this.setState({
        isMobile: true,
        isVisible: true,
      })
    }

    inView.offset({
      top: 100,
      right: 0,
      bottom: 50,
      left: 0,
    })

    const sectionZones = []
    // setTimeout(() => {
    //   Object.keys(anchors).forEach((anchor, index) => {
    //     const element = document.getElementById(anchor)
    //     sectionZones.push({ start: element.offsetTop - 10, end: element.offsetTop + element.scrollHeight - 10 })
    //   })
    //
    //   setInterval(() => {
    //     sectionZones.forEach((item, index) => {
    //       if ((main.scrollTop > item.start && main.scrollTop < item.end) || (window.scrollY > item.start && window.scrollY < item.end)) {
    //         this.setState({ currentSection: index })
    //       }
    //     })
    //   }, 1000)
    // }, 1000)
    this.languageMenuMarker.current.style.left = ""

    if (this.props.language === "en") {
      this.languageMenuMarker.current.style.left = "50%"
    }
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
    if (prevState.currentSection !== this.state.currentSection) {
      if (this.device.type === devices.desktop) {
        this.desktopMarkerAnimate(this.state.currentSection)
      } else if (this.device.type === devices.mobile) {

      }
    }

    window.addEventListener('scroll', this.onScroll)
  }

  onScroll = () => {


  }

  desktopMarkerAnimate = (sectionNumber: number = -1) => {
    // Start marker animation
    if (sectionNumber !== -1) {
      this.setState({ currentSection: sectionNumber })
      this.languageMenuMarker.current.style.left = sectionNumber === 0 ? "" : "50%"
    }
  }

  /* The function will work if the user clicks on a navigation menu item */
  clickOnMenuItem = (event) => {
    const target = event.currentTarget
    const targetSectionNumber = +target.dataset.number

    this.desktopMarkerAnimate(targetSectionNumber)
  }

  goToEn = async (event) => {
    this.desktopMarkerAnimate(1)
    window.location.href = "/en"
  }

  goToRu = async (event) => {
    this.desktopMarkerAnimate(0)
    window.location.href = "/ru"
  }

  scrollTo = (anchor) => {
    const section = document.getElementById(anchor)

    animateScroll.scrollTo(section.offsetTop,
      {
        duration: 1500,
        delay: 0,
        smooth: "easeInOutQuint",
        // containerId: "main",
      })
  }

  render() {
    return (
      <section id={"navigation-menu"} className={styles.navMenu} ref={this.navigationMenuWrapper}>
        <div className={styles.logo}>
          <IconLogo/>
          <ButtonWithLine direction={"right"} text={this.props.buttonsText.backButtonText}
                          action={this.toggleVisibleMobileMenu}
                          style={{ wrapper: { position: "static", transform: "translateX(20px)" } }}/>
        </div>
        <div className={styles.menuItems} ref={this.navigationMenu}>
          <div ref={this.mobileBurgerButton} onClick={this.toggleVisibleMobileMenu}><IconMobileMenu/></div>
          {
            this.props.menuItems.map((item, index) => (
              <ASMouseWrapperComponent
                key={index}
                className={styles.menuItemWrapper}
                onMouseEnter={(e) => window.transformMouse("line", ASMouseTransforms["line"], e.currentTarget.previousSibling)}
                onMouseLeave={() => window.returnDefault()}
                onClick={(e) => e.currentTarget.previousSibling.click()}
                style={{ width: "80%", transform: "translate3d(10%, -30%, 0)" }}>
                <div
                  // to={"#"+Object.keys(anchors)[index]}
                  // onClick={() => scrollTo("#" + Object.keys(anchors)[index])}
                  onClick={() => this.scrollTo(Object.keys(anchors)[index])}
                >
                <span
                  onClick={this.clickOnMenuItem}
                  className={`${styles.menuItem} ${this.state.currentSection === index ? styles.current : ""}`}
                  key={index}
                  ref={this.state.currentSection === index ? this.currentSection : undefined}
                  data-number={index}
                  data-anchor={Object.keys(anchors)[index]}
                >
                  {item}
                </span>
                </div>
              </ASMouseWrapperComponent>
            ))
          }
          <Marker reference={this.navigationMenuMarker}/>
        </div>
        {
          <div className={styles.languages} ref={this.languageMenu}>
          <span className={`${styles.language} ${this.state.activeLanguage === 0 ? styles.current : ""}`}
                onClick={this.goToRu}>RU</span>
            <span className={`${styles.language} ${this.state.activeLanguage === 1 ? styles.current : ""}`}
                  onClick={this.goToEn}>EN</span>
            <Marker reference={this.languageMenuMarker}/>
          </div>
        }
      </section>
    )
  }
}

NavMenu.defaultProps = {
  menuItems: [],
}

export default NavMenu