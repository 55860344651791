import React, { Component } from "react"
import { directions, sizes } from "../../utils/Enums"
import styles from "./ButtonWithLine.module.scss"

const componentThemes = Object.freeze({
  light: "light",
  dark: "dark",
})

type Directions = $Keys<typeof directions>;
type Sizes = $Keys<typeof sizes>;
type Themes = $Keys<typeof componentThemes>;

type Props = {
  text: string,
  size: Sizes,
  direction: Directions,
  theme: Themes,
  style: Object<any>,
  action: void,
}

class ButtonWithLine extends Component<Props> {
  wrapper = React.createRef()

  componentDidMount(): void {

    switch (this.props.direction) {
      case "up":
        this.wrapper.current.classList.add(styles.up)
        break
      case "down":
        this.wrapper.current.classList.add(styles.down)
        break
      case "left":
        this.wrapper.current.classList.add(styles.left)
        break
      case "right":
        this.wrapper.current.classList.add(styles.right)
        break
      default:
        this.wrapper.current.classList.add(styles[this.props.direction])
        break
    }

    switch (this.props.theme) {
      case "dark":
        this.wrapper.current.classList.add(styles.dark)
        break
      case "light":
        this.wrapper.current.classList.add(styles.light)
        break
      default:
        this.wrapper.current.classList.add(styles[this.props.style])
        break
    }
  }

  onClick = (event) => {
    this.props.action(event)
  }

  render() {
    return (
      <div role={`buttonWithLine_${this.props.direction}`} onClick={this.onClick}
           className={styles.wrapper}
           style={this.props.style.wrapper} ref={this.wrapper}>
        {
          (this.props.direction === "right" || this.props.direction === "down") &&
          <React.Fragment>
            <span className={styles.text} style={this.props.style.text}>{this.props.text}</span>
            <div className={styles.line} style={this.props.style.line} ref={this.line}/>
          </React.Fragment>
        }
        {
          (this.props.direction === "left" || this.props.direction === "up") &&
          <React.Fragment>
            <div className={styles.line} style={this.props.style.line} ref={this.line}/>
            <span className={styles.text} style={this.props.style.text}>{this.props.text}</span>
          </React.Fragment>
        }
      </div>
    )
  }
}

ButtonWithLine.defaultProps = {
  text: "",
  size: "small",
  direction: "right",
  theme: "dark",
  style: {
    wrapper: {},
    line: {},
    text: {},
  },
  action: () => {
  },
}

export default ButtonWithLine