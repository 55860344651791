import React, { Component } from "react"
import IconPlay from "../../assets/Icons/IconPlay"
import styles from "./Video.module.scss"

type Props = {
  width: ?number,
  height: ?number,
  poster: string,
  video: string,
}

class Video extends Component<Props> {
  video = React.createRef()
  wasPlayed = false

  startVideo = () => {
    if (this.wasPlayed === false) {
      this.wasPlayed = true
      this.video.current.classList.add(styles.activated)
      this.video.current.children[0].play()
    }
  }

  render() {
    return (
      <div className={styles.video} ref={this.video} onClick={this.startVideo}>
        <video
          style={{
            width: this.props.width,
            height: this.props.height,
          }}
          controls={true}
          poster={this.props.poster}>
          <source src={this.props.video}/>
        </video>
        <IconPlay/>
      </div>
    )
  }
}

Video.defaultProps = {
  width: "",
  height: "",
  poster: "",
  video: "",
}

export default Video