import React, { Component } from "react"
import Portal from "../../utils/Portal"
import styles from "./MainScreen.module.scss"
import ButtonWithLine from "../ButtonWithLine/ButtonWithLine"
import YouTube from "react-youtube"

class ShowReelPopUp extends Component {
  initialState = {
    portalRootProps: {
      style: { display: "none", opacity: 0 },
      classList: [],
    },
  }

  state = { ...this.initialState }
  showreel = React.createRef()
  player = null

  componentDidMount() {
    this.showreel = document.getElementById("showreel")
    this.showreel.style.height = this.showreel.clientWidth / 1.78 + "px"
  }



  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    if (prevProps.active !== this.props.active) {
      if (this.props.active) {
        this.setState({
          portalRootProps: {
            ...this.state.portalRootProps,
            style: {
              ...this.state.portalRootProps.style,
              display: "",
            },
          },
        })
        setTimeout(() => this.setState({
          portalRootProps: {
            ...this.state.portalRootProps,
            style: {
              ...this.state.portalRootProps.style,
              opacity: 1,
            },
          },
        }), 50)
      } else {
        this.setState({
          portalRootProps: {
            ...this.state.portalRootProps,
            style: {
              ...this.state.portalRootProps.style,
              opacity: 0,
            },
          },
        })
        setTimeout(() => this.setState({
          portalRootProps: {
            ...this.state.portalRootProps,
            style: {
              ...this.state.portalRootProps.style,
              display: "none",
            },
          },
        }), 800)
      }
    }

    setTimeout(() => {
      this.showreel.style.height = "9px"
    }, 1400)
  }

  onReady = (e) => {
    this.player = e.target
  }

  render() {
    return (
      <Portal portalRootProps={this.state.portalRootProps}>
        <section className={styles.showreelSection} style={{
          opacity: this.state.portalRootProps.style.opacity,
          display: this.state.portalRootProps.style.display === "" ? "flex" : this.state.portalRootProps.style.display,
        }}>
          <YouTube
            ref={this.showreel}
            id={"showreel"}
            onReady={this.onReady}
            onPause={this.onStopVideo}
            videoId={this.props.showReelYouTubeID}
            containerClassName={styles.showreelContainer}
            className={styles.youtubeShowreel}
          />

          <div className={styles.backBtn}>
            <ButtonWithLine theme={"light"} direction={"left"} text={this.props.backButtonsText}
                            action={() => {
                              if (this.player !== null) {
                                this.player.stopVideo()
                              }
                              this.props.closePopUp()
                            }}/>
          </div>
          <div className={styles.backBtn + " " + styles.mobile}>
            <ButtonWithLine theme={"light"} direction={"right"} text={this.props.backButtonsText}
                            action={this.props.closePopUp} style={{ wrapper: { left: "unset", right: 0 } }}/>
          </div>
        </section>
      </Portal>
    )
  }
}

ShowReelPopUp.defaultProps = {
  width: "auto",
  height: "auto",
}

export default ShowReelPopUp
