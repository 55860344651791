import React, { Component } from "react"
import styles from "./AppearingGrid.module.scss"

class AppearingGrid extends Component {
  grid = React.createRef()

  render() {
    return (
      <div className={styles.appearingGrid} ref={this.grid}>
        {this.props.children}
      </div>
    )
  }
}

AppearingGrid.defaultProps = {
  children: [],
}

export default AppearingGrid