import React, { Component } from "react"
import styles from "./Client.module.scss"
import Img from "gatsby-image"

// type Props = {
//   name: string,
//   cover: Object<any>,
// }

class Client extends Component<Props> {
  render() {
    return (
      <article className={styles.client} ref={this.props.reference}>
        {
          this.props.cover &&
          <img src={this.props.cover} alt={"client"} className={styles.cover}/>
        }
      </article>
    )
  }
}

export default Client