import React, { Component } from "react"
import styles from "./ASMouse.module.scss"
import { defineCurrentDevice } from "../../utils/AS-Scripts"
import { devices } from "../../utils/Enums"

class ASMouse extends Component {
  initialState = { style: {}, lock: false, radius: 0 }
  state = { ...this.initialState }
  cursor = React.createRef()
  follower = React.createRef()
  isTransform = false

  followerRAF

  xmouse
  ymouse
  x = void 0
  y = void 0
  dx = void 0
  dy = void 0

  currentTarget = null
  device

  componentDidMount(): void {
    this.device = defineCurrentDevice()
    window.addEventListener("mousemove", (event) => {
      if (this.device.type !== devices.desktop) {
        return
      }
      this.xmouse = event.clientX
      this.ymouse = event.clientY

      this.cursor.current.style.top = event.clientY + "px"
      this.cursor.current.style.left = event.clientX - 3 + "px"
    })

    window.addEventListener("mousewheel", () => this.returnDefault(0))

    this.follow()
    window.transformMouse = this.transformMouse
    window.returnDefault = this.returnDefault
  }

  returnTimeout = null
  transformTimeout = null
  transformInterval = null
  transformMouse = (name, transformObject, target = null) => {
    this.isTransform = true
    clearTimeout(this.returnTimeout)

    // document.body.style.cursor = "none"
    this.currentTarget = target
    const styles = {
      ...transformObject.style,
      transform: transformObject.style.transform !== "none" ? transformObject.style.transform + " rotate(45deg)" : "none",
    }

    let rect
    switch (name) {
      case "nav-item":
        break
      case "line":
        if (!target) {
          return
        }
        rect = target.getBoundingClientRect()
        this.follower.current.style.transition = "top .3s, left .3s, width .0s, height .3s"
        styles.top = rect.top + rect.height + 2
        styles.left = rect.left
        styles.width = 0
        styles.borderColor = "#111111"
        this.transformTimeout = setTimeout(() => {
          this.follower.current.style.transition = "top .3s, left .3s, width .3s, height .3s"
          this.setState({
            style: { ...styles, width: rect.width, borderColor: "white" },
          })
        }, 300)
        break
      case "magnet-arrow":
        if (!target) {
          return
        }
        rect = target.getBoundingClientRect()
        styles.top = rect.top + "px"
        styles.left = rect.left + "px"
        this.follower.current.style.transition = "all .3s"
        break
      case "founder":
        if (!target) {
          return
        }
        rect = target.getBoundingClientRect()
        styles.top = rect.top + "px"
        styles.left = rect.left + "px"
        styles.width = rect.width + "px"
        styles.height = rect.height - 5 + "px"
        this.follower.current.style.transition = "all .4s"
        this.setState({ lock: transformObject.lock ?? false })
        break
      default:
        break
    }

    this.cursor.current.style.opacity = 0
    this.setState({
      style: styles,
      lock: transformObject.lock,
      radius: transformObject.radius || this.initialState.radius,
    })
  }

  returnDefault = (pause = 200) => {
    this.isTransform = false
    this.returnTimeout = setTimeout(() => {
      clearInterval(this.transformInterval)
      clearTimeout(this.transformTimeout)

      if (this.currentTarget !== null) {
        const target = this.currentTarget
        const rect = target.getBoundingClientRect()

        const dx = Math.abs(this.xmouse - (rect.x + (rect.width / 2) + window.pageXOffset))
        const dy = Math.abs(this.ymouse - (rect.y + (rect.height / 2) + window.pageYOffset))

        const dist = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2))
        if (dist < this.state.radius) {
          setTimeout(this.returnDefault, 10)
        } else {
          this.cursor.current.style.opacity = ""
          document.body.style.cursor = ""
          this.follower.current.style.transition = ""
          this.follower.current.style.transitionDelay = ""
          this.setState({ style: {}, lock: false })
          const checkFunc = (count = 1) => {
            if (this.isTransform === false && Object.keys(this.state.style).length > 0) {
              this.setState({ style: {} })
            } else if (count <= 3) {
              setTimeout(() => checkFunc(count + 1), 100)
            }
          }
          setTimeout(() => checkFunc(1), 100)
        }
      }
    }, pause)
  }

  follow = () => {
    if (this.state.lock) {
      this.followerRAF = window.requestAnimationFrame(this.follow)
      return
    }

    if (!this.x || !this.y) {
      this.x = this.xmouse
      this.y = this.ymouse
    } else {
      this.dx = (this.xmouse - this.x) * 0.125
      this.dy = (this.ymouse - this.y) * 0.125
      if (Math.abs(this.dx) + Math.abs(this.dy) < 0.1) {
        this.x = this.xmouse
        this.y = this.ymouse
      } else {
        this.x += this.dx
        this.y += this.dy
      }
    }

    this.follower.current.style.top = (2 + this.y) + "px"
    this.follower.current.style.left = (2 + this.x) + "px"

    this.followerRAF = window.requestAnimationFrame(this.follow)
  }


  render() {
    return (
      <>
        <div className={styles.cursor} ref={this.cursor} onMouseEnter={(e) => e.preventDefault()}/>
        <div className={styles.follower} ref={this.follower} style={{ ...this.state.style }}
             onMouseEnter={(e) => e.preventDefault()}/>
      </>
    )
  }
}

export default ASMouse