import React, { Component } from "react"
import Img from "gatsby-image"
import styles from "./Project.module.scss"
import moment from "moment"
import "moment/locale/ru"
import Slider from "../../Slider/Slider"

moment.locale("ru")

type Props = {
  id: string,
  name: string,
  description: {
    description: string,
  },
  creationDate: string,
  cover: Object<any>,
  slides: Array<any>,
  isActive: boolean,
  activateThisProject: void,
  projectLoaded: void,
}

class Project extends Component<Props> {
  projectRef = React.createRef()
  coverRef = React.createRef()

  constructor(props) {
    super(props)
    this.project = (
      <Img objectFit="none" className={`${styles.cover} ${this.props.isActive ? styles.active : ""}`}
           fluid={this.props.cover.fluid}
           onLoad={this.props.projectLoaded}
           ref={this.coverRef}
           style={{ width: "max-content", height: "max-content" }}
      />
    )

    this.creationDateBlock = (
      <span className={styles.creationDate}>
          {moment(this.props.creationDate).format("D MMMM")}
      </span>
    )
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
    this.coverRef.current.onclick = () => {
      this.props.activateThisProject()
    }
    if (this.props.isActive !== prevProps.isActive) {
      if (this.props.isActive) {
        this.projectRef.current.children[0].classList.add(styles.active)
      } else {
        this.projectRef.current.children[0].classList.remove(styles.active)
      }
    }
  }

  render() {
    return (
      <article className={styles.project}
               data-block-target={true}
               ref={this.projectRef}
               onClick={this.props.activateThisProject}
               style={{ zIndex: this.props.isActive ? 3 : "unset" }}>
        {this.project}
        {this.creationDateBlock}
        <Slider
          id={this.props.id}
          buttonsText={this.props.buttonsText}
          nextProjectText={this.props.nextProjectText}
          openProjectButton={this.props.openProjectButton}
          projectName={this.props.name}
          projectShortDescription={this.props.shortDescription}
          goSap={(e) => {
            e.stopPropagation()
            this.props.goSap()
          }}
          goFap={(e) => {
            e.stopPropagation()
            this.props.goFap()
          }}
          otherProjects={this.props.otherProjects}
          creationDate={this.props.creationDate} slides={this.props.slides}
          gallery={this.props.gallery}/>
      </article>
    )
  }
}

export default Project