import { deviceOrientations, devices } from "./Enums"

export const defineCurrentDevice = (): Object<any> => {
  const device = {}
  device.screenWidth = document.querySelector("body").clientWidth
  device.screenHeight = document.querySelector("body").clientHeight

  device.orientation = device.screenWidth > device.screenHeight ? deviceOrientations.horizontal : deviceOrientations.vertical
  if (device.orientation === deviceOrientations.vertical) {
    if (device.screenWidth > 1024) {
      device.type = devices.desktop
    } else if (device.screenWidth >= 768) {
      device.type = devices.tablet
    } else {
      device.type = devices.mobile
    }
  } else if (device.orientation === deviceOrientations.horizontal) {
    if (device.screenWidth - device.screenHeight >= 500) {
      device.type = devices.desktop
    } else if (device.screenWidth - device.screenHeight >= 300) {
      device.type = devices.tablet
    } else {
      device.type = devices.mobile
    }
  }

  return device
}