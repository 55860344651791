import React, { Component } from "react"

class IconPlay extends Component {
  render() {
    return (
      <svg role={"play-icon"} width="66" height="67" viewBox="0 0 66 67" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="45" height="45" transform="matrix(0.706824 0.707389 -0.706824 0.707389 32.7148 0.929688)"
              fill="white"/>
        <path d="M28.4697 41.9972V25.0039L41.2045 33.5006L28.4697 41.9972Z" fill="#111111"/>
      </svg>
    )
  }
}

export default IconPlay